import React, { forwardRef, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { getCompanyList, getEvents } from 'src/requests/manager';
import {
  ActivityEvent,
  AmountTypes,
  CompanyOption,
  EventActivityData,
  EventsRequest,
  EventTypes,
  IModalField,
  TableRow,
} from 'src/consts/types';
import FullCalendarModal from '../main/Calendar/FullCalendar/FullCalendarModal';
import YearSelector from 'src/components/yearSelector';
import Select from 'src/components/select';
import Modal from 'src/components/modal/Modal';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Calendar, Download, Pen } from 'lucide-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  findEventsByFilters,
  getEventsByObjectId,
  getObjectsByCompanyId,
} from 'src/requests/events';
import { EditableCell } from './editable-cell.component';
import {
  activityCreateFields,
  activityFields,
  activityHeaders,
  activityModalTabs,
  activityNotificationFields,
  activityTableFilters,
  AmountTypeOptions,
  checkupBackgroundOptions,
  EventActivityTypeOptions,
  EventCheckupTypeOptions,
  getActivityType,
  getEventType,
} from 'src/consts/events';

const PAGE_TYPE = 'CHECKUP';

const initialData: TableRow[] = [
  {
    companyName:
      'Акционерное общество "Соколовско-Сарбайское горно-обогатительное производственное объединение"',
    objectName:
      'Рудоподготовительные, вспомогательные и ремонтные объекты Рудненской промышленной площадки',
    eventName: 'ТЭЦ. Модернизация котлов с заменой золоулавливающего оборудования',
    workAmount: '25 тонн/год',
    start: 'май 2021',
    end: 'декабрь 2027',
    eventAmount: 520000,
    used: 50,
    done: 30,
    effect: 100000,
    status: 'не начато',
    additional: 'причины невыполнения а еще много доп слов для чека тексареая',
  },
];

const defaulteFilterState = {
  eventType: [{ label: 'Мероприятие', value: EventTypes.ACTIVITY }],
  amountType: [{ value: AmountTypes.NONE, label: 'Без затрат' }],
  companyId: [],
  objectId: [],
};

const CheckupPage = () => {
  const [date, setDate] = useState(new Date());
  const [eventsData, setEventsData] = useState<EventsRequest>({});
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [selectedCompany, setSelectedCompany] = useState<CompanyOption[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<'new' | 'notification'>('new');
  const [companyList, setCompanyList] = useState<CompanyOption[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingEvent, setEditingEvent] = useState<any>(null);
  const [tableData, setTableData] = useState<TableRow[]>(initialData);

  const currentDate = new Date();
  const years = Array.from({ length: 26 }, (_, i) => currentDate.getFullYear() - 5 + i);

  const [dicts, setDicts] = useState<{ [key: string]: any[] }>({
    companyList: companyList,
    objectList: [],
    eventTypeList: EventActivityTypeOptions,
    statusList: [],
    eventAmountList: AmountTypeOptions,
  });

  const [formState, setFormState] = useState<{ [key: string]: any }>({});
  const [filterState, setFilterState] = useState<{ [key: string]: any }>(defaulteFilterState);

  const handleChangeCompany = (value: CompanyOption[]) => {
    const ids = value.map((item) => item.id);
    ids.length
      ? getObjectsByCompanyId(ids).then((res) => {
          const formattedList = res.list.map((item: any) => ({
            ...item,
            label: item.name,
            value: item.id,
          }));
          setDicts((prev) => ({ ...prev, objectList: formattedList }));
        })
      : setDicts((prev) => ({ ...prev, objectList: [] }));
  };

  const handleChangeObject = (value: CompanyOption[]) => {
    const ids = value.map((item) => item.id);
    ids.length
      ? getEventsByObjectId(ids).then((res) => {
          const formattedList = res.list
            .filter((item: EventActivityData) => item.type === PAGE_TYPE)
            .map((item: EventActivityData) => ({
              ...item,
              label: item.eventName,
              value: item.id,
            }));
          setDicts((prev) => ({ ...prev, eventsList: formattedList }));
        })
      : setDicts((prev) => ({ ...prev, eventsList: [] }));
  };

  const handleTableFilterChange = (key: string, value: any) => {
    setFilterState((prev) => ({ ...prev, [key]: value }));

    if (key === 'companyId') {
      handleChangeCompany(value);
    }
    console.log(key, value);
  };

  const handleInputChange = (key: string, value: any, type?: 'select' | 'date' | 'date-range') => {
    if (type === 'select') {
      if (formState && formState[key] && formState[key].length === value.length) {
        setFormState((prev) => ({ ...prev, [key]: [] }));
      } else {
        setFormState((prev) => ({ ...prev, [key]: value }));
      }

      if (key === 'companyName') {
        handleChangeCompany(value);
      }

      if (key === 'objectName') {
        handleChangeObject(value);
      }

      return;
    }
    setFormState((prev) => ({ ...prev, [key]: value }));
  };

  const handleTableInputChange = (index: number, key: keyof TableRow, value: string | number) => {
    if (key === 'done') {
      if (
        Number(value) < 0 ||
        Number(value) > 100 ||
        isNaN(Number(value)) ||
        value.toString().length >= 4
      ) {
        console.log(value);
        return;
      }
    }
    setTableData((prevData) =>
      prevData.map((row, i) => (i === index ? { ...row, [key]: value } : row)),
    );
  };

  const formattedDate = (date: Date) =>
    format(new Date(date), 'dd MMMM yyyy, EEEE', { locale: ru });

  const filterActivityEvents = (eventData: Record<string, any[]>): EventsRequest => {
    return Object.entries(eventData).reduce<EventsRequest>((acc, [date, events]) => {
      const activityEvents = events.filter((event) => event.type === PAGE_TYPE);

      if (activityEvents.length > 0) {
        acc[date] = activityEvents;
      }

      return acc;
    }, {});
  };

  const findEarliestEvent = (eventData: any): ActivityEvent | null => {
    if (!eventData || Object.keys(eventData).length === 0) return null;

    const currentDate = new Date().getTime();
    const allEvents: any[] = Object.values(eventData).flat();

    const futureEvents = allEvents.filter((event) => new Date(event.date).getTime() >= currentDate);
    const pastEvents = allEvents.filter((event) => new Date(event.date).getTime() < currentDate);

    futureEvents.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    pastEvents.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    return futureEvents[0] || pastEvents[0] || null;
  };

  const formattedValue = (value: string | number, key: string) => {
    if (key === 'eventAmount') {
      return value?.toLocaleString('ru-RU');
    } else if (key === 'deadline') {
      const monthName = ru.localize?.month(new Date(value).getMonth(), { width: 'wide' });
      return `${monthName} ${format(date, 'yyyy')}`;
    } else {
      return value;
    }
  };

  useEffect(() => {
    const dateParams = {
      from: new Date(date.getFullYear(), date.getMonth(), 1),
      to: new Date(date.getFullYear() + 1, date.getMonth(), 1),
    };
    getEvents(dateParams)
      .then((res) => {
        console.log(res);
        const filteredData = filterActivityEvents(res.data);

        setEventsData(filteredData);

        setSelectedEvent(findEarliestEvent(filteredData));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [date]);

  useEffect(() => {
    getCompanyList().then((res) => {
      const formattedList = res.list.map((item: any) => ({
        ...item,
        label: item.name,
        value: item.id,
      }));
      setCompanyList(formattedList);
      setSelectedCompany(formattedList);
      setDicts((prev) => ({ ...prev, companyList: formattedList }));
    });

    findEventsByFilters(defaulteFilterState).then((res) => {
      console.log(res);
    });
  }, []);

  useEffect(() => {
    console.log(filterState);
    if (filterState.amountType.length !== 0) {
      findEventsByFilters(filterState).then((res) => {
        setTableData(res.list);
      });
    }
  }, [filterState]);

  const DateInput = forwardRef(
    (
      {
        value,
        onClick,
      }: {
        value: string;
        onClick?: () => void;
      },
      ref,
    ) => (
      <div className={classes['date-input-wrapper']}>
        <div className={classes['date-input-wrapper__body']}>
          <input
            type="text"
            value={value}
            className={classes['create-modal__field-input']}
            readOnly
          />
          <div className={classes['icon-wrapper']}>
            <Calendar className={classes['calendar-icon']} onClick={onClick} />
          </div>
        </div>
      </div>
    ),
  );
  DateInput.displayName = 'DateInput';

  return (
    <div className={classes.events}>
      <div className={classes.events__title}>Проверки</div>

      <div className={classes.content}>
        <div className={classes.calendar}>
          <div className={classes.calendar__header}>
            <div className={classes.calendar__header__left}>
              <YearSelector
                option={years}
                selected={date.getFullYear()}
                onChange={(val: number) => setDate(new Date(val, 0, 1))}
              />

              <Select
                options={companyList}
                selectedOptions={selectedCompany}
                handleSelect={setSelectedCompany}
                placeholder="Выберите предприятие"
                labelKey="label"
                valueKey="value"
                sources
                isMultiple
                maxLength={companyList.length}
              />
            </div>

            <div className={classes.calendar__header__right}>
              <div className={classes['color-list']}>
                {EventCheckupTypeOptions.map((item) => (
                  <div key={item.value} className={classes['list-item']}>
                    <span style={{ background: item.color }}></span>
                    {item.label}
                  </div>
                ))}
              </div>
              <button className={classes.events__button} onClick={() => setIsOpen(true)}>
                Создать
              </button>
            </div>
          </div>
          <FullCalendarModal
            date={date}
            eventsData={eventsData}
            key={date.toString()}
            isEvents
            backgroundOptions={checkupBackgroundOptions}
            customOnClick={(event) => setSelectedEvent(event[0])}
            selectedDate={selectedEvent?.date ? new Date(selectedEvent.date) : undefined}
          />
        </div>

        {selectedEvent && (
          <div className={classes['info-card']}>
            <div className={classes['info-card__header']}>
              <div className={classes['info-card__date']}>
                {selectedEvent?.date && formattedDate(selectedEvent?.date)}
              </div>
              <div
                className={classes['info-card__type']}
                style={{ background: getEventType(selectedEvent?.checkupType, PAGE_TYPE)?.color }}
              >
                {selectedEvent?.isNotification
                  ? 'Напоминание'
                  : getEventType(selectedEvent?.checkupType, PAGE_TYPE)?.label}
              </div>
            </div>
            <div className={classes['info-card__body']}>
              {activityFields.map((field) => (
                <div key={field.key} className={classes['info-card__field-group']}>
                  <span className={classes['info-card__field-title']}>{field.title}: </span>
                  <span
                    className={`${classes['info-card__field-value']} ${
                      classes[`info-card__field-value--${field.key}`]
                    }`}
                  >
                    {formattedValue(selectedEvent[field.key], field.key)}
                  </span>
                </div>
              ))}
            </div>

            <button
              className={classes['info-card__edit-button']}
              onClick={() => {
                setIsEditing(true);
                setIsOpen(true);
                setEditingEvent(selectedEvent);
              }}
            >
              <Pen />
              Редактировать
            </button>
          </div>
        )}
      </div>

      <div className={classes.table}>
        <div className={classes.table__filters}>
          {activityTableFilters.map((filter) => (
            <div key={filter.key} className={classes['table__filter-group']}>
              <span className={classes['table__filter-title']}>{filter.title}</span>
              <div className={classes['table__filter-input']}>
                <Select
                  options={dicts[filter.dictKey] || []}
                  selectedOptions={filterState[filter.key]}
                  handleSelect={(e) => {
                    handleTableFilterChange(filter.key, e);
                  }}
                  placeholder={filter.title}
                  labelKey="label"
                  valueKey="value"
                  maxLength={dicts[filter.dictKey]?.length}
                  sources
                  isMultiple
                  style={{
                    border: '1px solid #3838741A',
                  }}
                  showDeselectAll={false}
                />
              </div>
            </div>
          ))}

          {/* <div
            className={`${classes['table__filter-group']} ${classes['table__filter-group--right']}`}
          >
            <span className={classes['table__filter-title']}></span>
            <div
              className={`${classes['table__filter-input--date']} ${classes['table__filter-input']}`}
            >
              <DatePicker
                selected={filterState['dates']?.[0] || null}
                onChange={(dates) => setFilterState({ ...editingEvent, ['dates']: dates })}
                startDate={filterState['dates']?.[0] || null}
                endDate={filterState['dates']?.[1] || null}
                selectsRange
                dateFormat="dd-MM-yyyy"
                className={classes['create-modal__field-input']}
                customInput={
                  <DateInput
                    value={
                      filterState['deadline']
                        ? `${filterState['deadline'][0]?.toLocaleDateString(
                            'ru-RU',
                          )} - ${filterState['deadline'][1]?.toLocaleDateString('ru-RU')}`
                        : ''
                    }
                  />
                }
              />
            </div>
          </div> */}
        </div>

        <div className={classes.download}>
          <Download size={24} />
          <span>Скачать отчет</span>
        </div>

        <div className={classes['table-container']}>
          <div className={classes['table-wrapper']}>
            <table>
              <thead>
                <tr>
                  {activityHeaders.map((header) =>
                    header.children ? (
                      <th colSpan={header.children.length} key={header.key}>
                        {header.title}
                      </th>
                    ) : (
                      <th rowSpan={2} key={header.key}>
                        {header.title}
                      </th>
                    ),
                  )}
                </tr>
                <tr>
                  {activityHeaders
                    .filter((header) => header.children)
                    .flatMap(
                      (header) =>
                        header.children?.map((child) => <th key={child.key}>{child.title}</th>),
                    )}
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    {activityHeaders.map((header) =>
                      header.key === 'period' ? (
                        <>
                          <td key="start">{row.start}</td>
                          <td key="end">{row.end}</td>
                        </>
                      ) : header.key === 'done' || header.key === 'additional' ? (
                        <td key={header.key}>
                          <EditableCell
                            value={row[header.key]}
                            onSave={(newValue) =>
                              handleTableInputChange(index, header.key as keyof TableRow, newValue)
                            }
                            type={header.key === 'done' ? 'number' : 'textarea'}
                          />
                        </td>
                      ) : (
                        <td key={header.key}>{row[header.key as keyof TableRow]}</td>
                      ),
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal
        show={isOpen}
        handleClose={() => {
          setIsOpen(false);
          setIsEditing(false);
          setEditingEvent(null);
        }}
      >
        {isEditing ? (
          <div className={`${classes['create-modal']} ${classes['edit-modal']}`}>
            {activityFields.map((field) => (
              <div key={field.key} className={classes['info-card__field-group']}>
                <span className={classes['info-card__field-title']}>{field.title}: </span>
                {field.key !== 'deadline' ? (
                  <span className={classes['info-card__field-value']}>
                    {formattedValue(editingEvent[field.key], field.key)}
                  </span>
                ) : (
                  <DatePicker
                    selected={editingEvent[field.key]?.[0] || null}
                    onChange={(dates) => setEditingEvent({ ...editingEvent, [field.key]: dates })}
                    startDate={editingEvent[field.key]?.[0] || null}
                    endDate={editingEvent[field.key]?.[1] || null}
                    selectsRange
                    dateFormat="dd-MM-yyyy"
                    className={classes['create-modal__field-input']}
                    customInput={
                      <DateInput
                        value={
                          editingEvent['deadline']
                            ? `${editingEvent['deadline'][0]?.toLocaleDateString(
                                'ru-RU',
                              )} - ${editingEvent['deadline'][1]?.toLocaleDateString('ru-RU')}`
                            : ''
                        }
                      />
                    }
                  />
                )}
              </div>
            ))}

            <div
              className={classes['create-modal__footer']}
              onClick={() => {
                console.log(editingEvent);
                setIsOpen(false);
                setIsEditing(false);
              }}
            >
              <button className={classes['events__button']}>Сохранить</button>
            </div>
          </div>
        ) : (
          <>
            <div className={classes['create-modal']}>
              <div className={classes['create-modal__header']}>
                {activityModalTabs.map((tab) => (
                  <div
                    key={tab.value}
                    className={`${classes['create-modal__tab']} ${
                      selectedTab === tab.value ? classes['create-modal__tab--selected'] : ''
                    }`}
                    onClick={() => setSelectedTab(tab.value)}
                  >
                    {tab.label}
                  </div>
                ))}
              </div>

              <div className={classes['create-modal__body']}>
                {(selectedTab === 'new' ? activityCreateFields : activityNotificationFields).map(
                  (
                    field: IModalField, // выбор полей в зависимости от выбранной вкладки
                  ) => (
                    <div key={field.key} className={classes['create-modal__field-group']}>
                      <span className={classes['create-modal__field-title']}>{field.title}</span>
                      {field.type === 'select' && field.dictKey ? (
                        <div className={classes['create-modal__field-wrapper']}>
                          <Select
                            options={dicts[field.dictKey] || []}
                            selectedOptions={formState[field.key]}
                            handleSelect={(e) => handleInputChange(field.key, e, field.type)}
                            placeholder={field.title}
                            labelKey="label"
                            valueKey="value"
                            showAllButton={false}
                            style={{ maxHeight: '3rem' }}
                          />
                        </div>
                      ) : field.type === 'date' ? (
                        <DatePicker
                          selected={formState[field.key] || null}
                          onChange={(date: any) => handleInputChange(field.key, date)}
                          dateFormat="dd-MM-yyyy"
                          className={classes['create-modal__field-input']}
                          customInput={
                            <DateInput
                              value={
                                formState['date']
                                  ? formState['date'].toLocaleDateString('ru-RU')
                                  : ''
                              }
                            />
                          }
                        />
                      ) : field.type === 'date-range' ? (
                        <DatePicker
                          selected={formState[field.key]?.[0] || null}
                          onChange={(dates) => handleInputChange(field.key, dates)}
                          startDate={formState[field.key]?.[0] || null}
                          endDate={formState[field.key]?.[1] || null}
                          selectsRange
                          dateFormat="dd-MM-yyyy"
                          className={classes['create-modal__field-input']}
                          customInput={
                            <DateInput
                              value={
                                formState['deadline']
                                  ? `${formState['deadline'][0]?.toLocaleDateString(
                                      'ru-RU',
                                    )} - ${formState['deadline'][1]?.toLocaleDateString('ru-RU')}`
                                  : ''
                              }
                            />
                          }
                        />
                      ) : (
                        <input
                          type="text"
                          className={classes['create-modal__field-input']}
                          placeholder={field.title}
                        />
                      )}
                    </div>
                  ),
                )}
              </div>

              <div
                className={classes['create-modal__footer']}
                onClick={() => {
                  console.log(formState);
                  setIsOpen(false);
                }}
              >
                <button className={classes['events__button']}>Создать</button>
              </div>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default CheckupPage;
