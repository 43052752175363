import React, { FC, useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { ReactComponent as ChatIcon } from 'src/assets/images/chat-logo.svg';
import { SendButton } from 'src/assets/icons';
import { IChatMessage } from 'src/consts/types';

interface IProps {
  messages: IChatMessage[];
  handleSendMessage: (message: string) => void;
  isLoading: boolean;
}

const Chat: FC<IProps> = ({ messages, handleSendMessage, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const chatRef = useRef<HTMLDivElement | null>(null);
  const chatBodyRef = useRef<HTMLDivElement | null>(null);

  const handleOutsideClick = (e: MouseEvent) => {
    const chatButton = document.getElementById('chat-button');

    if (
      chatRef.current &&
      !chatRef.current.contains(e.target as Node) &&
      chatButton !== e.target &&
      !chatButton?.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(target.value);
  };

  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !isLoading) {
      handleSendMessage(query);
      setQuery('');
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages, isOpen]);

  return (
    <div className={classes.chat}>
      <div className={classes.chat__container}>
        <div id="chat-button" className={classes.button} onClick={() => setIsOpen(!isOpen)}>
          <ChatIcon />
        </div>
        {isOpen && (
          <div className={classes.chat__body} ref={chatRef}>
            <div className={classes.chat__messages} ref={chatBodyRef}>
              {messages.map((message) => (
                <div
                  key={message.time}
                  className={`${classes['chat__message']} ${
                    message.role === 'user' ? classes['chat__message--user'] : ''
                  }`}
                >
                  <div className={classes['chat__message__body']}>{message.content}</div>
                </div>
              ))}
              {isLoading && (
                <div className={classes['chat__message']}>
                  <div className={classes['loader']} />
                </div>
              )}
            </div>
            <div className={classes['chat__input-container']}>
              <input
                type="text"
                placeholder="Напишите мне"
                value={query}
                onChange={handleChange}
                onKeyUp={handleSubmit}
              />
              <button
                className={classes['chat__send-button']}
                onClick={() => {
                  if (!isLoading) {
                    handleSendMessage(query);
                    setQuery('');
                  }
                }}
              >
                <img src={SendButton} alt="send-button" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
