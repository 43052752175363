import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { CalendarIcon, LinkIcon } from 'src/assets/icons';

function CalendarHeader({
  date,
  setDate,
  showFullCalendar,
}: {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  showFullCalendar: () => void;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div className={styles['header-calendar']}>
      <div className={styles['date-info']}>
        <span>{date.toLocaleString('default', { month: 'long' })},</span>
        <p>{date.getFullYear()}</p>
      </div>
      <div className={styles['calendar-icons']}>
        <div className={`${styles['calendar-icon']} ${styles['icon-modal']}`}>
          <img src={CalendarIcon} alt="calendar" onClick={() => setIsOpen(!isOpen)} />
          {isOpen && (
            <div className={styles['modal']}>
              <Calendar
                value={date}
                view="year"
                onClickMonth={(value) => {
                  setDate(value);
                  setIsOpen(false);
                }}
              />
            </div>
          )}
        </div>
        <div className={styles['calendar-icon']} onClick={() => showFullCalendar()}>
          <img src={LinkIcon} alt="calendar" />
        </div>
      </div>
    </div>
  );
}

export default CalendarHeader;
