import { api } from './api';

const baseUrl = '/chat';

export const sendMessage = async (content: string) => {
  return api.post(`${baseUrl}/send`, { content }).then((res) => res.data);
};

export const getChatHistory = async () => {
  return api.get(`${baseUrl}/history`).then((res) => res.data);
}