import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { ICompany, ISource } from 'src/consts/types';
import {
  findTopByLatitude,
  findLeftByLongitude,
  findByRuCompanyName,
  findColorById,
} from 'src/utils/helpers';
import { getCompanySourceList, getDivisionsById } from 'src/requests/source';
import KazakhstanMap from './KazakhstanMap';
import { MapContainer, useMap, Marker, Popup, Tooltip } from 'react-leaflet';
import classes from './styles.module.scss';
import L from 'leaflet';
import { ORGicon } from 'src/assets/icons';
import classNames from 'classnames';

export interface IMapPoint extends ICompany {
  top: number;
  left: number;
}

interface Props {
  points: ICompany[];
  setSourcesList: Dispatch<SetStateAction<ISource[]>>;
  setSelectedCompany: Dispatch<SetStateAction<ICompany | undefined>>;
  setSelectedSource: Dispatch<SetStateAction<ISource | undefined>>;
  isDivision?: boolean;
}

const FitBoundsOnResize: React.FC<{ bounds: L.LatLngBoundsLiteral }> = ({ bounds }) => {
  const map = useMap();
  const hasSetBounds = useRef(false);

  useEffect(() => {
    if (!hasSetBounds.current) {
      map.fitBounds(bounds);
      hasSetBounds.current = true;
    }

    const handleResize = () => {
      map.fitBounds(bounds);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [bounds, map]);

  return null;
};

const customIcon = (backgroundColor: string) =>
  new L.Icon({
    iconUrl: ORGicon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
    className: classes['custom-icon'],
    iconStyle: {
      backgroundColor,
    },
  });

const Map: React.FC<Props> = ({
  points,
  setSourcesList,
  setSelectedCompany,
  setSelectedSource,
  isDivision,
}) => {
  const [mapPoints, setMapPoints] = useState<IMapPoint[]>([]);
  const mapContainerRef = useRef<HTMLDivElement>(null);

  // Координаты границ Казахстана
  const bounds: L.LatLngBoundsLiteral = [
    [39.49, 46.492],
    [55.63, 87.3152],
  ];

  useEffect(() => {
    const found = points.map((item) => ({
      ...item,
      top: findTopByLatitude(item.latitude),
      left: findLeftByLongitude(item.longitude),
      backgroundColor: findColorById(item.id),
    }));
    setMapPoints(found);
  }, [points]);

  return (
    <div
      className={classes['map']}
      style={{ position: 'relative', width: '100%', height: '700px' }}
      ref={mapContainerRef}
    >
      <MapContainer
        style={{ width: '100%', height: '100%' }}
        center={[48, 68]}
        zoom={5}
        attributionControl={false}
        zoomControl={true}
        dragging={false}
        scrollWheelZoom={true}
        maxBounds={bounds}
        maxZoom={32}
        minZoom={4}
        zoomSnap={0.001}
      >
        <FitBoundsOnResize bounds={bounds} />
        <KazakhstanMap
          points={mapPoints}
          isDivision={isDivision}
          handleIconClick={(item, companyName) => {
            const companyEnName = findByRuCompanyName(companyName);
            setSelectedCompany(item);
            (isDivision ? getDivisionsById : getCompanySourceList)([item.id]).then((res) => {
              const filteredList = res.list.filter((item: any) =>
                item.name.includes(companyEnName),
              );
              setSourcesList(isDivision ? filteredList : res.list);
              setSelectedSource(isDivision ? filteredList[0] : res.list[0]);
            });
          }}
        />
      </MapContainer>
    </div>
  );
};

export default Map;
