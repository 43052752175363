import React, { Dispatch, SetStateAction, useState } from 'react';
import classes from './styles.module.scss';
import CardInfo from './CardInfo';
import { ICompany, IDivision, ISource } from 'src/consts/types';
import Card from 'src/components/card';
import Sidebar from '../sidebar';
import ToggleSwitch from 'src/components/toggle';
import Map from './AsmMap';

interface Props {
  selectedSource?: ISource;
  setSelectedSource: Dispatch<SetStateAction<ISource | undefined>>;
  selectedCompany?: ICompany;
  setSelectedCompany: Dispatch<SetStateAction<ICompany | undefined>>;
  companyListWithLevel: ICompany[];
  companyList: ICompany[];
  setCompanyListWithLevel: Dispatch<SetStateAction<ICompany[]>>;
  setDivisionListWithLevel: Dispatch<SetStateAction<IDivision[]>>;
  divisionsList: IDivision[];
  showDivisions: boolean;
  divisionsListWithLevel: IDivision[];
  setShowDivisions: Dispatch<SetStateAction<boolean>>;
}
const MapPage: React.FC<Props> = ({
  selectedSource,
  setSelectedSource,
  companyListWithLevel,
  selectedCompany,
  setSelectedCompany,
  companyList,
  setCompanyListWithLevel,
  divisionsList,
  showDivisions,
  setShowDivisions,
  divisionsListWithLevel,
  setDivisionListWithLevel,
}) => {
  const [sourcesList, setSourcesList] = useState<ISource[]>([]);
  const points = showDivisions ? divisionsList : companyListWithLevel;
  const handleCloseSourceCard = () => {
    setSelectedCompany(undefined);
  };

  const handleMinusClick = () => {
    console.log('minus');
  };

  const handlePlusClick = () => {
    console.log('handlePlusClick');
  };

  const filteredCompanyList = companyListWithLevel
    .map((company) => ({
      ...company,
      divisions: company.divisions.filter((division) =>
        divisionsListWithLevel.some((divisionItem) => divisionItem.name.includes(division.name)),
      ),
    }))
    .filter((company) => company.divisions.length > 0);

  return (
    <div className={classes['wrapper']}>
      <div
        style={{
          width: '75%',
        }}
      >
        <Sidebar
          isDivision={showDivisions}
          companyList={showDivisions ? divisionsList : companyList}
          onCompanySelected={showDivisions ? setDivisionListWithLevel : setCompanyListWithLevel}
          handleMinusClick={handleMinusClick}
          handlePlusClick={handlePlusClick}
        />
        <Map
          isDivision={showDivisions}
          points={showDivisions ? filteredCompanyList : companyListWithLevel}
          setSourcesList={setSourcesList}
          setSelectedCompany={setSelectedCompany}
          setSelectedSource={setSelectedSource}
        />
      </div>

      {selectedCompany ? (
        <CardInfo
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
          sourcesList={sourcesList}
          selectedCompany={selectedCompany}
          onClose={handleCloseSourceCard}
          showDivisions={showDivisions}
          changeCardInfoType={() => setShowDivisions((prev) => !prev)}
        />
      ) : (
        <Card
          styles={{
            width: '25%',
          }}
        >
          <div className={classes['company-list']}>
            <div>
              <p>{showDivisions ? 'Дивизион' : 'Предприятие'}</p>
              <div>
                {points?.map((item) => (
                  <div key={item.id} className={classes['company-list__flex']}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                    >
                      <circle cx="5" cy="5" r="5" fill={item.backgroundColor} />
                    </svg>
                    <span>{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes['company-list__counts']}>
              <p>Источники</p>
              <div>
                {!showDivisions
                  ? companyListWithLevel?.map((item) => (
                      <span key={item.id}>{item.sourceCount}</span>
                    ))
                  : divisionsList?.map((item) => <span key={item.id}>{item.sources.length}</span>)}
              </div>
            </div>
            <ToggleSwitch
              tooltipText={showDivisions ? 'Предприятие' : 'Дивизион'}
              checked={showDivisions}
              onToggleChange={() => setShowDivisions((prev) => !prev)}
            />
          </div>
        </Card>
      )}
    </div>
  );
};

export default MapPage;
