import { FC, useRef, useState } from 'react';
import { GeoJSON, Marker, Tooltip, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import kazakhstanGeoJSON from '../../../assets/geojson/kz.json';
import { IMapPoint } from './Map';
import { ORGicon } from 'src/assets/icons';
import L from 'leaflet';
import classes from './styles.module.scss';
import { findColorById } from 'src/utils/helpers';
import classNames from 'classnames';
import MarkerClusterGroup from 'react-leaflet-cluster';

const regionNamesMap: Record<string, string> = {
  Astana: 'Акмолинская',
  Aktobe: 'Актюбинская',
  'Almaty (city)': ' ',
  Almaty: 'Алматинская',
  Atyrau: 'Атырауская',
  'East Kazakhstan': 'Восточно-Казахстанская',
  Mangystau: 'Мангистауская',
  'North Kazakhstan': 'Северо-Казахстанская',
  Pavlodar: 'Павлодарская',
  Karaganda: 'Карагандинская',
  Kostanay: 'Костанайская',
  Kyzylorda: 'Кызылординская',
  'Shymkent (city)': 'Шымкент',
  'West Kazakhstan': 'Западно-Казахстанская',
  Jambyl: 'Жамбылская',
  Abai: 'Абай',
  Jetisu: 'Жетысу',
  Ulytau: 'Улытау',
  Akmola: ' ',
  Turkestan: 'Туркестанская',
};

const KazakhstanMap: FC<{
  points: IMapPoint[];
  isDivision?: boolean;
  handleIconClick: (item: any, companyName?: any) => void;
}> = ({ points, isDivision, handleIconClick }) => {
  const [selectedMarker, setSelectedMarker] = useState<number | null>(null);
  const defaultStyle = {
    fillColor: '#FFFFFF',
    color: '#29349533',
    weight: 2,
    fillOpacity: 0.7,
  };

  const handleClick = (item: any, companyName?: string) => {
    setSelectedMarker(item.id);
    handleIconClick(item, companyName);
  };

  const onEachRegion = (feature: any, layer: any) => {
    layer.on('mouseover', (e: any) => {
      const layer = e.target;
      layer.bringToFront();
    });

    const regionNameEnglish = feature.properties?.name || 'Unknown Region';
    const regionNameRussian = regionNamesMap[regionNameEnglish] || regionNameEnglish;

    layer.bindTooltip(regionNameRussian, {
      permanent: true,
      direction: 'center',
      className: 'region-label',
    });
  };

  const customIcon = (backgroundColor: string) =>
    new L.Icon({
      iconUrl: ORGicon,
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
      className: classes['custom-icon'],
      iconStyle: {
        backgroundColor,
      },
    });

  return (
    <>
      <GeoJSON
        data={kazakhstanGeoJSON as GeoJSON.FeatureCollection}
        style={defaultStyle}
        onEachFeature={onEachRegion}
      />
      <MarkerClusterGroup
        maxClusterRadius={50}
        spiderfyOnMaxZoom={false}
        showCoverageOnHover={false}
        zoomToBoundsOnClick={true}
        disableClusteringAtZoom={8}
      >
        {points.map((item) => (
          <Marker
            key={item.id}
            position={[Number(item.latitude), Number(item.longitude)]}
            icon={customIcon(item.backgroundColor || '#000')}
          >
            <Tooltip direction="center" permanent className={classes['tooltip']}>
              <span key={item.name} className={`${classes['org-point__wrapper']}`}>
                <span
                  style={{ top: `${item.top - 15}%`, left: `${item.left - 7}%` }}
                  className={classNames(classes[`circle`], classes[`circle--${item.level}`])}
                />
                <div
                  className={classes['org-point']}
                  style={{ top: `${item.top - 5}%`, left: `${item.left - 3}%` }}
                  onClick={() => !isDivision && handleClick(item)}
                >
                  {isDivision ? (
                    item.divisions.map((division) => (
                      <div
                        className={classes['org-point__division']}
                        key={division.id}
                        onClick={() => isDivision && handleClick(division, item.name)}
                      >
                        <img
                          src={ORGicon}
                          alt="icon"
                          style={{ backgroundColor: findColorById(division.id) }}
                        />
                        <p>{division.name}</p>
                      </div>
                    ))
                  ) : (
                    <>
                      <img
                        src={ORGicon}
                        alt="icon"
                        style={{ backgroundColor: item.backgroundColor }}
                      />
                      <p>{item.name}</p>
                    </>
                  )}
                </div>

                {selectedMarker === item.id && (
                  <span className={`${classes['org-point__infobox']}`}>
                    <div className={`${classes['org-point__infobox__left']}`}>
                      <img
                        src={ORGicon}
                        alt="icon"
                        style={{ backgroundColor: item.backgroundColor }}
                        className={`${classes['org-point__infobox-icon']}`}
                      />
                    </div>
                    <div
                      className={`${classes['org-point__infobox__right']}`}
                      onWheel={(e) => e.stopPropagation()}
                    >
                      <ul>
                        <li>
                          <span>
                            1. Сумма платежа по 880 форме за последний квартал -
                            {item.amountSumBy880}
                          </span>
                        </li>
                        <li>
                          <span>
                            2. Количество наложенных административных взысканий за последние 5 лет -
                            {item.id}
                          </span>
                        </li>
                        <li>
                          <span>
                            3. Сумма реализации по ППМ на текущий год - {item.realizedSumByPPM}
                          </span>
                        </li>
                        <li>
                          <span>
                            4. Сумма реализации по ППМ на 5 лет - {item.realizedSumByPPM5}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </span>
                )}
              </span>
            </Tooltip>
          </Marker>
        ))}
      </MarkerClusterGroup>
    </>
  );
};

export default KazakhstanMap;
