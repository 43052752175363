import { useEffect, useRef, useState } from 'react';
import { CalendarDay } from 'react-day-picker';
import styles from './styles.module.scss';
import {
  EventActivityData,
  EventCheckupData,
  EventDeadline,
  EventProjectData,
  EventsRequest,
} from 'src/consts/types';
import { format } from 'date-fns';
import {
  EventActivityType,
  EventCheckupType,
  EventName,
  EventProjectType,
  EventType,
} from 'src/consts/enum';

export default function CalendarCardItem({
  day,
  groupedEvents,
  date,
  uniqueGroupedEvents,
  modifiers,
}: {
  day: CalendarDay;
  groupedEvents: EventsRequest;
  date: Date;
  uniqueGroupedEvents: EventsRequest;
  modifiers: Record<string, boolean>;
}) {
  const dateStr = format(day.date, 'yyyy-MM-dd');
  const events = groupedEvents[dateStr];
  const forIndikatorEvents = uniqueGroupedEvents[dateStr];
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isToday = modifiers.today;

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (events) {
    return (
      <td className={styles['evet-wrap']}>
        <div
          className={`${styles['date-day'] + ' ' + styles['event-day']} ${
            date.getMonth() !== day.date.getMonth() ? styles['other-month-day'] : ''
          } ${isOpen ? styles['show-events'] : ''} ${isToday ? styles['custom-today'] : ''}`}
          style={
            forIndikatorEvents.length > 1
              ? {}
              : {
                  background: EventType[forIndikatorEvents[0].type],
                  color: '#fff',
                }
          }
          ref={dropdownRef}
        >
          <div
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className={styles['day-info']}
          >
            <p>{day.date.getDate()}</p>
            {forIndikatorEvents.length > 1 && (
              <div className={styles['event-indicators']}>
                {forIndikatorEvents.map((event, index) => {
                  return (
                    <span
                      key={new Date(event.date).getTime() + index}
                      className={styles['event-circle']}
                      style={{
                        background: EventType[event.type],
                        marginLeft: index === 0 ? '0' : '-0.625rem',
                        zIndex:
                          index === 0
                            ? forIndikatorEvents.length
                            : forIndikatorEvents.length - index,
                      }}
                    ></span>
                  );
                })}
              </div>
            )}
          </div>
          <CustomTooltip events={events} />
        </div>
      </td>
    );
  } else {
    return (
      <td>
        <div
          className={`${styles['date-day']} ${
            date.getMonth() !== day.date.getMonth() ? styles['other-month-day'] : ''
          } ${isToday ? styles['custom-today'] : ''}`}
        >
          <p>{day.date.getDate()}</p>
        </div>
      </td>
    );
  }
}

const CustomTooltip = ({
  events,
}: {
  events: (EventActivityData | EventCheckupData | EventDeadline | EventProjectData)[];
}) => {
  const [content, setContent] = useState<number>(0);

  return (
    <div className={styles['custom_tooltip']}>
      <div className={styles['custom_tooltip__header']}>
        <div className={styles['event-name']}>
          <span
            style={{
              background: EventType[events[content].type],
              border: events[content].type === 'ACTIVITY' ? '1px solid #fff' : '',
            }}
          ></span>
          <p>{EventName[events[content].type]}</p>
        </div>
        {events.length > 1 && (
          <div className={styles['event-buttons']}>
            {events.map((event, index) => {
              return (
                <p
                  onClick={() => setContent(index)}
                  key={event.id}
                  className={index === content && styles['active-btn']}
                >
                  {index + 1}
                </p>
              );
            })}
          </div>
        )}
      </div>

      <div className={styles['custom_tooltip__block']}>
        <p>Наименование предприятия:</p>
        <div>{events[content].companyName}</div>
      </div>
      <div className={styles['custom_tooltip__block']}>
        <p>Наименование объекта:</p>
        <div>{events[content].objectName}</div>
      </div>
      {events[content].type === 'ACTIVITY' && (
        <div className={styles['custom_tooltip_blocks']}>
          <div className={styles['custom_tooltip__block']}>
            <p>Наименование мероприятия:</p>
            <div>{(events[content] as EventActivityData).eventName}</div>
          </div>
          <div className={styles['custom_tooltip__block']}>
            <p>Вид мероприятия:</p>
            <div>{EventActivityType[(events[content] as EventActivityData).activityType]}</div>
          </div>
          <div className={styles['custom_tooltip__block']}>
            <p>Общая стоимость:</p>
            <div>{(events[content] as EventActivityData).eventAmount}</div>
          </div>
          <div className={styles['custom_tooltip__block']}>
            <p>Срок выполнения: </p>
            <div>{format((events[content] as EventActivityData).deadline, 'yyyy-MM-dd')}</div>
          </div>
        </div>
      )}
      {events[content].type === 'CHECKUP' && (
        <div className={styles['custom_tooltip_blocks']}>
          <div className={styles['custom_tooltip__block']}>
            <p>Вид проверки:</p>
            <div>{EventCheckupType[(events[content] as EventCheckupData).checkupType]}</div>
          </div>
          <div className={styles['custom_tooltip__block']}>
            <p>Номер Акта о назначении проверки: </p>
            <div>{(events[content] as EventCheckupData).actNo}</div>
          </div>
        </div>
      )}
      {events[content].type === 'DEADLINE' && (
        <div className={styles['custom_tooltip_blocks']}>
          <div className={styles['custom_tooltip__block']}>
            <p>Срок действия разрешения:</p>
            <div>
              {format((events[content] as EventDeadline).initDate, 'yyyy-MM-dd') +
                ' - ' +
                format((events[content] as EventDeadline).deadline, 'yyyy-MM-dd')}
            </div>
          </div>
        </div>
      )}
      {events[content].type === 'PROJECT' && (
        <div className={styles['custom_tooltip_blocks']}>
          <div className={styles['custom_tooltip__block']}>
            <p>Наименование проекта:</p>
            <div>{(events[content] as EventProjectData).eventName}</div>
          </div>
          <div className={styles['custom_tooltip__block']}>
            <p>Вид мероприятия:</p>
            <div>{EventProjectType[(events[content] as EventProjectData).projectType]}</div>
          </div>
          <div className={styles['custom_tooltip__block']}>
            <p>Общая стоимость:</p>
            <div>{(events[content] as EventProjectData).eventAmount}</div>
          </div>
          <div className={styles['custom_tooltip__block']}>
            <p>Срок выполнения:</p>
            <div>{format((events[content] as EventProjectData).deadline, 'yyyy-MM-dd')}</div>
          </div>
        </div>
      )}
    </div>
  );
};
