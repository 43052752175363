import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { getAccessToken, removeTokens } from 'src/utils/token';
import Popover from '../popover';
import Icon from '../icon';
import { useUserContext } from '../../context/useUserContext';
import NotificationsPopover from './notifications-popover';
import classes from './styles.module.scss';
import { moduleList } from 'src/pages/home';

interface IModule {
  id: number;
  title: string;
  link: string;
  disabled: boolean;
  subModules?: IModule[];
}

const routes = [
  {
    label: 'АСМ',
    route: 'asm',
    subRoutes: [
      { label: 'Выбросы', route: '/asm/emissions' },
      { label: 'Сбросы', route: '/asm/discharges' },
      { label: 'Дэшборд', route: '/asm/dashboard' },
    ],
  },
  {
    label: 'События',
    route: 'events',
    subRoutes: [
      { label: 'Проверки', route: '/events/checkup' },
      { label: 'Мероприятия', route: '/events/activities' },
      { label: 'Проекты', route: '/events/projects' },
      { label: 'Разрешение', route: '/events/permission' },
    ],
  },
  {
    label: 'Отчеты',
    route: 'reports',
    subRoutes: [
      { label: 'Атмосферный воздух', route: '/reports/air' },
      { label: 'Водные ресурсы', route: '/reports/water' },
      { label: 'Отдоды ПП', route: '/reports/waste' },
      { label: 'Земельные ресурсы', route: '/reports/land' },
    ],
  },
  {
    label: 'ИИ',
    route: 'ai',
    subRoutes: [
      { label: 'Опция 1', route: '/ai/option1' },
      { label: 'Опция 2', route: '/ai/option2' },
    ],
  },
];

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, getUser } = useUserContext();
  const [isDropdownModule, setIsDropdownModule] = useState<boolean>(false);
  const [showSubModules, setShowSubModules] = useState<number>(0);
  const [expandMenu, setExpandMenu] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<string>('');
  const modulePopoverRef = useRef<{ close: () => void }>();
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

  // const routes = [
  //   { name: 'Главная', path: '/emissions', roles: 'ALL' },
  //   { name: 'Дэшборд', path: '/dashboard', roles: ['MANAGER', 'MAIN_ECOLOGIST'] },
  // ];

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const adjustDropdownPosition = () => {
    if (!dropdownRef.current) return;

    const dropdown = dropdownRef.current;
    const rect = dropdown.getBoundingClientRect();
    const isOverflowingRight = rect.right > window.innerWidth;
    const isOverflowingLeft = rect.left < 0;

    if (isOverflowingRight) {
      dropdown.style.left = 'auto';
      dropdown.style.right = '0px';
    } else if (isOverflowingLeft) {
      dropdown.style.left = '0px';
      dropdown.style.right = 'auto';
    }
  };

  useEffect(() => {
    adjustDropdownPosition();
    window.addEventListener('resize', adjustDropdownPosition);
    return () => window.removeEventListener('resize', adjustDropdownPosition);
  }, [activeDropdown]);

  const handleDropdownToggle = (route: any) => {
    if (route.subRoutes?.length === 0) {
      navigate(route.route);
    } else {
      setActiveDropdown((prev) => (prev === route.route ? null : route.route));
    }
  };

  useEffect(() => {
    const token = getAccessToken();
    if (!token) {
      navigate('/login');
    }
    if (!user) {
      getUser();
    }
  }, []);

  const handleLogOut = () => {
    removeTokens();
    navigate('/login');
  };

  const handleModuleClick = (module: IModule) => {
    if (module.id === showSubModules) {
      setShowSubModules(0);
    } else if (module.subModules) {
      setShowSubModules(module.id);
    } else {
      navigate(module.link);
      setActivePage(module.title);
    }
  };

  const isCurrentRoute = (route: string) => {
    return location.pathname === `/${route}` || location.pathname.startsWith(`/${route}/`);
  };

  useEffect(() => {
    moduleList.forEach((item) => {
      item.subModules?.forEach((subItem) => {
        if (subItem.link === location.pathname) {
          setActivePage(subItem.title);
        }
      });
    });
  }, []);

  const Navigation = useMemo(
    () => (
      <>
        {routes.map((route) => (
          <div
            onClick={() => handleDropdownToggle(route)}
            key={route.label}
            className={classes['layout__navigation__item-wrapper']}
          >
            <div
              className={classNames(
                classes['layout__navigation__item'],
                isCurrentRoute(route.route) && classes['layout__navigation__item--active'],
              )}
            >
              {route.label}
            </div>

            {!!route.subRoutes.length && activeDropdown === route.route && (
              <div ref={dropdownRef} className={classes['navigation-dropdown']}>
                {route.subRoutes.map((sub, index) => (
                  <div
                    key={index}
                    className={classes['navigation-dropdown__item']}
                    onClick={() => navigate(sub.route)}
                  >
                    {sub.label}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        {/* <Popover
          ref={modulePopoverRef}
          position={'bottom-right'}
          closeOnClick={false}
          setIsActive={(value) => setIsDropdownModule(value)}
          content={
            <div className={classes['dropdown__options']}>
              <div
                style={{ padding: '16px 32px', display: 'flex', justifyContent: 'space-between' }}
              >
                <div className={classes['dropdown__options__item']}>МОДУЛИ</div>
                <div
                  style={{ cursor: 'pointer' }}
                  className={classes['cross']}
                  onClick={() => {
                    modulePopoverRef?.current?.close();
                  }}
                />
              </div>
              {moduleList.map((item: IModule) => (
                <React.Fragment key={item.id}>
                  <div
                    className={classNames(
                      classes['dropdown__option-wrap'],
                      classes[`dropdown__option-wrap--${item.disabled && 'disabled'}`],
                    )}
                    style={{ opacity: item.disabled ? 0.5 : 1 }}
                    onClick={() => !item.disabled && handleModuleClick(item)}
                  >
                    <p>{item.title}</p>
                    {item.subModules && <span className={classes[`triangle-down`]}></span>}
                  </div>
                  {showSubModules === item.id &&
                    item.subModules?.map((item) => (
                      <div
                        key={item.id}
                        className={classNames(
                          classes['dropdown__option-wrap2'],
                          classes[`dropdown__option-wrap2--${item.disabled && 'disabled'}`],
                        )}
                        style={{ opacity: item.disabled ? 0.5 : 1 }}
                        onClick={() => !item.disabled && handleModuleClick(item)}
                      >
                        <p>{item.title}</p>
                      </div>
                    ))}
                </React.Fragment>
              ))}
            </div>
          }
        >
          <div
            style={{
              padding: '24px',
              cursor: 'pointer',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              userSelect: 'none',
            }}
          >
            <span>{activePage || 'Страница'}</span>
            <span className={classes[`triangle-${isDropdownModule ? 'up' : 'down'}`]}></span>
          </div>
        </Popover> */}
      </>
    ),
    [
      user,
      routes,
      modulePopoverRef,
      moduleList,
      showSubModules,
      isDropdownModule,
      activeDropdown,
      location.pathname,
    ],
  );

  const toggleMobileMenu = () => {
    setExpandMenu((prevState) => !prevState);
  };

  const getUserInitials = useMemo(() => {
    const firstName = user?.firstName ? user?.firstName[0] : '';
    const lastName = user?.lastName ? user?.lastName[0] : '';
    return firstName + lastName;
  }, [user]);

  if (!user) return null;

  return (
    <div className={classes['layout']}>
      <div className={classes['layout__header']}>
        <div className="df ai-c">
          {user && (
            <Popover
              content={
                <>
                  <div className={classes['layout__profile__header']}>
                    <span>{user.firstName + ' ' + user.lastName}</span>
                    <span>{user.position}</span>
                  </div>
                  <ul className={classes['layout__profile__menu']}>
                    <li
                      className={classes['layout__profile__menu__item']}
                      onClick={() => navigate('/settings')}
                    >
                      <Icon name={'Settings'} size={18} />
                      Настройки
                    </li>
                    <li
                      className={classes['layout__profile__menu__item']}
                      onClick={() => navigate('/messages')}
                    >
                      <Icon name={'Mail'} size={18} />
                      Мои сообщения
                    </li>
                    <li className={classes['layout__profile__menu__item']} onClick={handleLogOut}>
                      <Icon name={'LogOut'} size={18} />
                      Выход
                    </li>
                  </ul>
                </>
              }
            >
              <div className={classes['layout__profile']}>
                <span>{getUserInitials}</span>
              </div>
            </Popover>
          )}
          <NotificationsPopover />
        </div>
        <div className={classes['layout__navigation']}>{Navigation}</div>
        <button
          type="button"
          className={classes['layout__navigation--mobile']}
          onClick={toggleMobileMenu}
        >
          <Icon name={'Menu'} />
        </button>
      </div>

      {expandMenu && (
        <>
          <div className={classes['layout__overlay']} onClick={toggleMobileMenu} />
          <div className={classes['layout__mobileMenu']}>
            <Icon name={'X'} onClick={toggleMobileMenu} />
            {Navigation}
          </div>
        </>
      )}
      <div className={classes['layout__outlet']}>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
