import React, { useEffect, useRef } from 'react';
import classes from './modal.module.scss';

type ModalProps = {
  show: boolean;
  children: React.ReactNode;
  handleClose: () => void;
  header?: JSX.Element;
  footer?: JSX.Element;
  setShow?: (value: boolean) => void;
  onScroll?: (event: React.UIEvent<HTMLElement>) => void;
  style?: React.CSSProperties;
};

const Modal: React.FC<ModalProps> = ({
  onScroll,
  children,
  handleClose,
  show,
  header,
  footer,
  style,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div className={classes['modal']}>
      <div style={style} className={classes['modal-content']} ref={modalRef} onScroll={onScroll}>
        {header && <div className={classes['modal-header']}>{header}</div>}
        {children}
        <span className={classes['modal-close']} onClick={handleClose}>
          +
        </span>
        {footer && <div className={classes['modal-footer']}>{footer}</div>}
      </div>
    </div>
  );
};

export default Modal;
