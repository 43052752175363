export interface IAuthForm {
  email: string;
  password: string;
}

export interface IFilter {
  dateFrom?: number | string;
  dateTo?: number | string;
  sourceIds: number[];
  types: string[];
}

export interface ICompany {
  id: number;
  latitude: string;
  longitude: string;
  name: string;
  orgId: number;
  level: string;
  sourceCount: number;
  backgroundColor?: string;
  amountSumBy880: number;
  navCount: number;
  realizedSumByPPM: number;
  realizedSumByPPM5: number;
  divisions: {
    id: number;
    name: string;
  }[];
}

export interface IDivisionSource {
  companyId: number;
  deviceId: string;
  id: number;
  name: string;
}

export interface IDivision {
  id: number;
  name: string;
  sources: IDivisionSource[];
  backgroundColor?: string;
}
export interface ISource {
  id: number;
  name: string;
  deviceId: string;
  label?: string;
  exceeded: boolean;
}

export interface IExceedanceTableData {
  sourceName: string;
  params: Record<string, IExceedanceTableRow>;
}

export interface IExceedanceTableRow {
  minDate: number;
  maxDate: number;
  downValue: number;
  duration: number;
  type: string;
  upValue: number;
  fullDownValue: string;
  cminDate?: number;
  cmaxDate?: number;
  cdownValue?: number;
  cduration?: number;
  ctype?: string;
  cupValue?: number;
  cfullDownValue?: string;
}

export interface ISourceInfo {
  id: number;
  name: string;
  params: {
    [key: string]: {
      WasteConcentration: {
        limit: number;
        value: number;
      };
      Emissions: {
        limit: number;
        value: number;
      };
    };
  };
}

export interface IHomeInfo {
  sourceName: string;
  type: string;
  companyId: number;
  orgColor: string;
  params: {
    WasteConcentration: {
      limit: number;
      value: number;
      dist: number;
    };
    Emissions: {
      limit: number;
      value: number;
      dist: number;
    };
  };
}

export interface IDateRange {
  dateFrom: string;
  dateTo: string;
}

export interface IResponse<T> {
  success: boolean;
  data: T;
  msg?: string;
}

export interface IBase {
  id: number;
  email: string;
}

export interface IUser extends IBase {
  firstName: string;
  lastName: string;
  position: string;
  userPosition: '' | 'MANAGER' | 'ECOLOGIST' | 'MAIN_ECOLOGIST';
}

export type editUserDto = {
  activityType: string;
  city: string;
  email: string;
  firedDate: string;
  firstName: string;
  hiredDate: string;
  lastName: string;
  middleName: string;
  password: string;
  phoneNumber: string;
  position: string;
};

export type emissionTypes = 'CO' | 'H2S' | 'NO' | 'NO2' | 'SO2';

export type ChartDataType = {
  sourceName: string;
  typeCharts: {
    charts: {
      parameterKey: string;
      norma: number;
      param: { key: string; value: number }[];
    }[];
    type: emissionTypes;
  }[];
};

export type IExceedanceList = {
  divisionId: number;
  divisionName: string;
  id: number;
  companyName: string;
  emission: number;
  emissionLimit: number;
  sourceName: string;
  type: emissionTypes;
  wasteConcentration: number;
  wasteConcentrationLimit: number;
  logDate: number;
};

export type DiagramCompanyType = {
  listType: 'company';
  columnName: string;
  name: string;
  id: number;
  params: {
    title: string;
    norma: number;
    value: number;
  }[];
  totalNorma: number;
};

export type DiagramDivisionType = {
  listType: 'division';
  columnName: string;
  id: number;
  label: string;
  params: {
    title: string;
    norma: number;
    value: number;
  }[];
  totalNorma: number;
} & IDivision;

export type DiagramEmissionType = {
  listType: 'emission';
  columnName: string;
  name: string;
  id: number;
  params: {
    title: string;
    norma: number;
    value: number;
  }[];
  totalNorma: number;
};

export interface IMessage {
  createDate: Date;
  header: string;
  id: number;
  msg: string;
  read: boolean;
  sender: string;
  status: string;
}

export interface INotification {
  createDate: string;
  header: string;
  id: number;
  msg: string;
  secondMsg: string;
  seen: boolean;
}

export interface IChartData {
  name: string;
  typeChart: { norma: number; param: { date: string; param: number }[] };
}

export interface IDateSelection {
  key: string;
  startDate: Date;
  endDate: Date;
}

export interface IEmissionType {
  code: string;
  title: string;
}

export interface ParameterData {
  parameterKey: string;
  norma: number;
  param: { key: string; value: number }[];
}
export interface LastTwenty {
  success: boolean;
  msg: string | null;
  list: Array<SourceItem>;
  totalSize: number;
}

export interface SourceItem {
  sourceName: string;
  params: Parameters;
}

interface Parameters {
  Turbidity: number; // Мутность
  HydrogenIndex: number; // pH
  WasteWaterFlowmeter: number; // Расход сточных вод
  WasteWaterTemperature: number; // Температура сточных вод
  ElectricalConductivity: number; // Электропроводность
}

export interface EventsData {
  companyName: string;
  date: string;
  id: number;
  objectName: string;
  type: 'ACTIVITY' | 'CHECKUP' | 'DEADLINE' | 'PROJECT';
}

//мероприятие
export interface EventActivityData extends EventsData {
  eventName: string;
  activityType:
    | 'PPM' // Проект ППМ
    | 'LIQUIDATION'; //Ликвидация
  eventAmount: number;
  deadline: Date;
}

//Проверка
export interface EventCheckupData extends EventsData {
  checkupType:
    | 'PLANNED' // Плановая (ГО)
    | 'BY_PURPOSE' // По назначению (ГО)
    | 'INTERNAL_SEM' // Внутренняя (СЭМ)
    | 'INTERNAL_UK'; // Внутренняя (УК)
  actNo: string;
}

//Разрешения
export interface EventDeadline extends EventsData {
  deadline: Date;
  initDate: Date;
}

//Проекты
export interface EventProjectData extends EventsData {
  eventName: string;
  projectType:
    | 'BUILDING' // Строительный проект
    | 'EXQUISITE' // Изыскательные работы
    | 'SCIENTIFIC'; // Научные исследования
  eventAmount: number;
  deadline: Date;
}

export interface EventsRequest {
  [key: string]: (EventActivityData | EventCheckupData | EventDeadline | EventProjectData)[];
}

export interface IChatMessage {
  role: 'user' | 'assistant' | 'admin';
  content: string;
  time: string;
}


export enum AmountTypes {
  ABOVE_THRESHOLD = 'ABOVE_THRESHOLD',
  NONE = 'NONE',
  UNDER_THRESHOLD = 'UNDER_THRESHOLD',
}

export enum EventTypes {
  ACTIVITY = 'ACTIVITY',
  CHECKUP = 'CHECKUP',
  DEADLINE = 'DEADLINE',
  PROJECT = 'PROJECT',
}

export interface IFilterParams {
  companyId: string[];
  objectId: string[];
  amountType: AmountTypes[];
  eventType: EventTypes[];
}

export interface IModalField {
  key: string;
  title: string;
  type?: 'select' | 'date' | 'date-range';
  dictKey?: string;
  subTitle?: string;
}

export interface TableRow {
  companyName: string;
  objectName: string;
  eventName: string;
  workAmount: string;
  start: string;
  end: string;
  eventAmount: number;
  used: number;
  done: number;
  effect: number;
  status: string;
  additional: string;
}

export type ActivityEvent = {
  id: number;
  companyName: string;
  date: string;
  type: 'ACTIVITY';
  objectName: string;
  eventName: string;
  activityType: 'PPM' | 'LIQUIDATION';
  eventAmount: number;
  deadline: number;
};

export type CompanyOption = {
  label: string;
  value: number;
  [key: string]: any;
};
