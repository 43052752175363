import styles from './styles.module.scss';
import { CalendarDay, DayPicker } from 'react-day-picker';
import { ru } from 'react-day-picker/locale';
import FullCalendarCardItem from './FullCalendarCardItem';
import { EventsRequest } from 'src/consts/types';
import { ColorInfoDTO } from '..';

export default function FullCalendarCard({
  eventsData,
  date,
  backgroundOptions,
  customOnClick,
  selectedDate,
}: {
  eventsData: EventsRequest;
  date: Date;
  backgroundOptions: {
    key: string;
    options: ColorInfoDTO[];
  };
  customOnClick?: (event: any) => void;
  selectedDate?: Date;
}) {
  const today = new Date();
  const uniqueGroupedEvents = Object.entries(eventsData).reduce((acc, [date, events]) => {
    acc[date] = events.filter(
      (event, index, self) => self.findIndex((e) => e.type === event.type) === index,
    );
    return acc;
  }, {} as EventsRequest);

  const CustomDaycell = ({
    day,
    modifiers,
  }: {
    day: CalendarDay;
    modifiers: Record<string, boolean>;
  }) => {
    const isSelected =
      selectedDate &&
      day.date.getFullYear() === selectedDate.getFullYear() &&
      day.date.getMonth() === selectedDate.getMonth() &&
      day.date.getDate() === selectedDate.getDate();

    const newModifiers = { ...modifiers, selected: isSelected || false };

    return (
      <FullCalendarCardItem
        day={day}
        groupedEvents={eventsData}
        date={date}
        uniqueGroupedEvents={uniqueGroupedEvents}
        modifiers={newModifiers}
        backgroundOptions={backgroundOptions}
        customOnClick={customOnClick}
      />
    );
  };

  return (
    <div className={styles['full-calendar-item']}>
      <p className={styles['month-name']}>{date.toLocaleString('default', { month: 'long' })}</p>
      <DayPicker
        key={date.getTime()}
        month={date}
        components={{ Day: CustomDaycell }}
        modifiers={{ today }}
        classNames={{
          root: styles['custom-calendar'],
          weekday: styles['custom-weekday'],
          month_caption: styles['hide-element'],
          nav: styles['hide-element'],
          month_grid: styles['custom-table'],
          today: styles['custom-today'],
          selected: styles['custom-selected'],
        }}
        firstWeekContainsDate={1}
        locale={ru}
        formatters={{
          formatWeekdayName: (weekday) =>
            weekday.toLocaleDateString('ru-RU', { weekday: 'short' }).slice(0, 1),
        }}
      />
    </div>
  );
}
