import { FC, useEffect, useState } from 'react';
import styles from './SingleCalendar/styles.module.scss';
import ColorInfo from './SingleCalendar/ColorInfo';
import CalendarCard from './SingleCalendar/CalendarCard';
import CalendarHeader from './SingleCalendar/CalendarHeader';
import Modal from 'src/components/modal/Modal';
import FullCalendarModal from './FullCalendar/FullCalendarModal';
import FullCalendarHeader from './FullCalendar/FullCalendarHeader';
import { EventsRequest } from 'src/consts/types';
import { getEvents } from 'src/requests/manager';

export interface ColorInfoDTO {
  color: string;
  title: string;
}
export interface EventsDate {
  date: Date;
  color: string;
  event: string;
}

interface IProps {
  backgroundOptions: {
    key: string;
    options: ColorInfoDTO[];
  };
}

const Calendar: FC<IProps> = ({ backgroundOptions }) => {
  const [date, setDate] = useState(new Date());
  const [showFullCalendar, setShowFullCalendar] = useState(false);
  const [evetsData, setEventsData] = useState<EventsRequest>({});

  useEffect(() => {
    const dateParams = {
      from: new Date(date.getFullYear(), date.getMonth(), 1),
      to: showFullCalendar
        ? new Date(date.getFullYear() + 1, date.getMonth(), 1)
        : new Date(date.getFullYear(), date.getMonth() + 1, 1),
    };
    getEvents(dateParams)
      .then((res) => {
        setEventsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [date]);

  return (
    <div className={styles['calendar-wrap']}>
      <CalendarHeader
        date={date}
        setDate={setDate}
        showFullCalendar={() => {
          setShowFullCalendar(true);
        }}
      />
      <CalendarCard key={date.getTime()} eventsData={evetsData} date={date} />
      <ColorInfo />
      <Modal
        style={{
          maxWidth: '90vw',
          width: '100%',
          background: '#ffffffdb',
        }}
        setShow={setShowFullCalendar}
        show={showFullCalendar}
        handleClose={() => {
          setShowFullCalendar(false);
        }}
        header={<FullCalendarHeader date={date} setDate={setDate} />}
      >
        <FullCalendarModal
          backgroundOptions={backgroundOptions}
          eventsData={evetsData}
          date={date}
        />
      </Modal>
    </div>
  );
};

export default Calendar;
