import React, { useCallback, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { useUserContext } from 'src/context/useUserContext';
import MapPage from './Map';
import { getCompanyInfo, getExceedanceList } from 'src/requests/source';
import { IChatMessage, ICompany, IDivision, IExceedanceList, ISource } from 'src/consts/types';
import { format } from 'date-fns';
import { findColorById, getShortName } from 'src/utils/helpers';
import Modal from 'src/components/modal/Modal';
import Dropdown from 'src/components/dropdown';
import { MONTHS, YEARS } from 'src/consts/enum';
import CardSwiper, { ICard } from './CardGrid';
import Chat from 'src/components/chat';
import { getCompanyList, getCompanyNormsList } from 'src/requests/manager';
import { getChatHistory, sendMessage } from 'src/requests/chat';

const PAGE_SIZE = 40;

const MainPage: React.FC = () => {
  const today = new Date();
  const { checkRole } = useUserContext();
  const [showHighCompanyModal, setShowHighCompanyModal] = useState<boolean>(false);
  const [companyList, setCompanyList] = useState<ICompany[]>([]);
  const [companyListWithLevel, setCompanyListWithLevel] = useState<ICompany[]>([]);
  const [divisionListWithLevel, setDivisionListWithLevel] = useState<IDivision[]>([]);
  const [companyDivisionList, setCompanyDivisionList] = useState<IDivision[]>([]);
  const [firstSelectedCompany, setFirstSelectedCompany] = useState<ICompany>();
  const [showDivisions, setShowDivisions] = useState<boolean>(false);

  const [exceedanceListLoading, setExceedanceListLoading] = useState<boolean>(false);
  const [exceedanceList, setExceedanceList] = useState<IExceedanceList[]>([]);
  const [exceedanceListPage, setExceedanceListPage] = useState<number>(0);
  const [exceedanceListPageSize] = useState<number>(PAGE_SIZE);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [exceedanceDate, setExceedanceDate] = useState<{ year: string; month: string }>({
    year: today.getFullYear().toString(),
    month: (today.getMonth() + 1).toString(),
  });

  const [cards, setCards] = useState<ICard[]>([]);
  const [messages, setMessages] = useState<IChatMessage[]>([
    {
      role: 'assistant',
      content: 'Здравствуйте! Чем могу помочь?',
      time: new Date().toISOString(),
    },
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSendMessage = (message: string) => {
    if (!isLoading) {
      setIsLoading(true);
      setMessages((prev) => [
        ...prev,
        { role: 'user', content: message, time: new Date().toISOString() },
      ]);

      sendMessage(message)
        .then((res) => {
          setMessages((prev) => [
            ...prev,
            { role: 'assistant', content: res.data.msg, time: res.time },
          ]);
        })
        .catch(() => {
          setMessages((prev) => [
            ...prev,
            { role: 'assistant', content: 'Произошла ошибка', time: new Date().toISOString() },
          ]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const fetchCompanyInfo = async () => {
    const companyPromises = companyList.map(async (item) => {
      const res = await getCompanyInfo(item.id);
      return {
        ...item,
        backgroundColor: findColorById(item.id),
        level: res.data.level,
        label: item.name,
        name: getShortName(item.name),
      };
    });
    const updatedCompanies = await Promise.all(companyPromises);
    const doubled = [
      ...updatedCompanies,
      ...updatedCompanies.map((item) => ({
        ...item,
        id: +(item.id + '1' + +new Date()),
        latitude: (+item.latitude + 0.1).toString(),
        longitude: (+item.longitude + 0.1).toString(),
      })),
      ...updatedCompanies.map((item) => ({
        ...item,
        id: +(item.id + '2' + +new Date()),
        latitude: (+item.latitude + 0.2).toString(),
        longitude: (+item.longitude + 0.2).toString(),
      })),
    ];
    setCompanyListWithLevel(doubled);
  };

  const fetchDivisionInfo = async () => {
    const divisionPromises = companyDivisionList.map(async (item) => {
      const res = await getCompanyInfo(item.id);
      return {
        ...item,
        backgroundColor: findColorById(item.id),
        level: res.data.level,
        label: item.name,
        name: getShortName(item.name),
      };
    });

    const updatedDivisions = await Promise.all(divisionPromises);
    setDivisionListWithLevel(updatedDivisions);
  };

  const getAmountOfHighLevelCompanies = () => {
    const date =
      exceedanceDate.year +
      '-' +
      (exceedanceDate.month.split('').length === 1
        ? '0' + exceedanceDate.month
        : exceedanceDate.month);

    setExceedanceListLoading(true);
    getExceedanceList(date, exceedanceListPage, exceedanceListPageSize)
      .then((res) => {
        setHasMore(res.list.length > 0);
        setExceedanceList((prev) => [...prev, ...res.list]);
      })
      .finally(() => {
        setExceedanceListLoading(false);
      });
  };

  useEffect(() => {
    setExceedanceListPage(0);
    setHasMore(false);
    setExceedanceList([]);
    setExceedanceListLoading(false);
  }, [exceedanceDate]);

  useEffect(() => {
    if (companyList.length > 0) {
      void fetchCompanyInfo();
    }
  }, [companyList]);

  useEffect(() => {
    if (!checkRole('ECOLOGIST') && companyDivisionList.length > 0) {
      void fetchDivisionInfo();
    }
  }, [companyDivisionList]);

  useEffect(() => {
    showHighCompanyModal && getAmountOfHighLevelCompanies();
  }, [exceedanceDate, showHighCompanyModal, exceedanceListPage]);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
      if (
        (Math.ceil(clientHeight + scrollTop) === scrollHeight ||
          Math.ceil(clientHeight + scrollTop + 1) === scrollHeight) &&
        hasMore &&
        !exceedanceListLoading
      ) {
        setExceedanceListPage((prevPage) => prevPage + 1);
      }
    },
    [exceedanceListLoading, hasMore],
  );

  useEffect(() => {
    getCompanyList().then((res) => {
      setCompanyList(res.list);
    });

    getCompanyNormsList().then((res: { list: ICard[] }) => {
      const list = res.list.map((item) => ({
        ...item,
        color: findColorById(item.id),
      }));
      setCards(list);
    });

    getChatHistory().then((res) => {
      setMessages((prev) => [
        ...prev,
        ...res.list.reverse().map((item: any) => ({
          role: item.user,
          content: item.msg,
          time: item.timestamp,
        })),
      ]);
    });
  }, []);

  return (
    <>
      <div className={`${classes['content']} ${classes['streched']}`}>
        <MapPage
          divisionsListWithLevel={divisionListWithLevel}
          showDivisions={showDivisions}
          setShowDivisions={setShowDivisions}
          selectedSource={{} as ISource}
          setSelectedSource={() => null}
          companyListWithLevel={companyListWithLevel}
          companyList={companyList}
          selectedCompany={firstSelectedCompany}
          setSelectedCompany={setFirstSelectedCompany}
          setCompanyListWithLevel={setCompanyListWithLevel}
          divisionsList={companyDivisionList}
          setDivisionListWithLevel={setDivisionListWithLevel}
        />

        {!!cards.length && <CardSwiper items={cards} />}
      </div>
      <Modal
        style={{
          maxHeight: '800px',
        }}
        onScroll={handleScroll}
        setShow={setShowHighCompanyModal}
        show={showHighCompanyModal}
        header={
          <div className={classes['high-company-modal-wrapper']}>
            <div className={classes['high-company-modal-wrapper__left']}>
              {showDivisions ? 'Дивизионы' : 'Предприятия'} с превышениями
            </div>
            <div className={classes['high-company-modal-wrapper__right']}>
              <Dropdown
                placeholder={MONTHS[today.getMonth()].label}
                options={MONTHS}
                onSelect={(month) => {
                  setExceedanceDate({ ...exceedanceDate, month: month.toString() });
                }}
              />
              <Dropdown
                placeholder={exceedanceDate.year}
                options={YEARS}
                onSelect={(year) => {
                  setExceedanceDate({ ...exceedanceDate, year: year.toString() });
                }}
              />
            </div>
          </div>
        }
        handleClose={() => {
          setShowHighCompanyModal(false);
          setExceedanceList([]);
        }}
      >
        <table className={classes['table']}>
          <thead
            style={{
              top: '-30px',
            }}
          >
            <tr>
              <th>{showDivisions ? 'Дивизион' : 'Предприятие'}</th>
              <th>Источник</th>
              <th>Загрязняющее вещество</th>
              <th>
                Норматив ПДВ, <br />
                г/с
              </th>
              <th>
                Превышение норматива ПДВ, <br />
                г/с
              </th>
              <th>
                Норматив ПДК, <br /> мг/нм3
              </th>
              <th>
                Превышение норматива ПДК, <br /> мг/нм3
              </th>
              <th>Дата и время Превышений</th>
            </tr>
          </thead>
          <tbody>
            {exceedanceList.length > 0 &&
              exceedanceList.map((item: IExceedanceList, index: number) => (
                <tr key={index}>
                  <td className={classes['flex']}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                    >
                      <circle
                        cx="5"
                        cy="5"
                        r="5"
                        fill={findColorById(item[showDivisions ? 'divisionId' : 'id'])}
                      />
                    </svg>
                    {showDivisions ? item.divisionName : getShortName(item.companyName)}
                  </td>
                  <td>{item.sourceName}</td>
                  <td>{item.type}</td>
                  <td>{item?.emissionLimit?.toFixed(4)}</td>
                  <td style={{ color: '#FF3D3D' }}>{item.emission}</td>
                  <td>{item?.wasteConcentrationLimit?.toFixed(4)}</td>
                  <td style={{ color: '#FF3D3D' }}>{item.wasteConcentration}</td>
                  <td>{format(new Date(item.logDate), 'dd.MM.yyyy HH:mm')}</td>
                </tr>
              ))}

            {exceedanceListLoading && (
              <tr>
                <td colSpan={9}>
                  <>Загрузка данных...</>
                </td>
              </tr>
            )}

            {exceedanceList.length == 0 && !exceedanceListLoading && (
              <tr>
                <td colSpan={9}>
                  Нет данных по&nbsp;
                  {MONTHS[Number(exceedanceDate.month) - 1].label + ' ' + exceedanceDate.year}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Modal>
      <Chat messages={messages} handleSendMessage={handleSendMessage} isLoading={isLoading} />
    </>
  );
};

export default MainPage;
