import { IFilterParams } from 'src/consts/types';
import { api } from './api';

export const getObjectsByCompanyId = async (companyId: string[]) => {
  const companyIds = companyId.map((id) => `companyId=${id}`).join('&');
  return api.get(`/object/list/byCompany?${companyIds}`).then((res) => res.data);
};

export const getEventsByObjectId = async (objectId: string[]) => {
  const objectIds = objectId.map((id) => `objectId=${id}`).join('&');
  return api.get(`/event/list/byObject?${objectIds}`).then((res) => res.data);
};

export const findEventsByFilters = async (filters: {
  [key: string]: { value: string; label: string }[];
}) => {
  const { companyId, objectId, amountType, eventType } = filters;

  const queryString = new URLSearchParams();

  if (eventType?.length) eventType.forEach((type) => queryString.append('eventType', type.value));
  if (amountType?.length)
    amountType.forEach((type) => queryString.append('amountType', type.value));
  if (companyId?.length) companyId.forEach((id) => queryString.append('companyId', id.value));
  if (objectId?.length) objectId.forEach((id) => queryString.append('objectId', id.value));

  return api.get(`/event/list?${queryString.toString()}`).then((res) => res.data);
};
