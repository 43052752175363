import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { ArrowBlack } from 'src/assets/icons';
import { ICompany, ISource, ISourceInfo } from 'src/consts/types';
import { getEmissionsDashboard } from 'src/requests/source';
import Card from 'src/components/card';
import { ERG } from 'src/assets/images';
import classNames from 'classnames';
import ToggleSwitch from 'src/components/toggle';

type PropsType = {
  selectedSource?: ISource;
  onClose?: () => void;
  selectedCompany?: ICompany;
  sourcesList?: ISource[];
  setSelectedSource: Dispatch<SetStateAction<ISource | undefined>>;
  showDivisions: boolean;
  changeCardInfoType: () => void;
};

const CardInfo: React.FC<PropsType> = ({
  selectedSource,
  setSelectedSource,
  selectedCompany,
  sourcesList,
  showDivisions,
  changeCardInfoType,
}) => {
  const [showSourcesList, setShowSourcesList] = useState<boolean>(false);
  const [sourcesInfoList, setSourcesInfoList] = useState<ISourceInfo[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleChooseSource = (source: ISource) => {
    setSelectedSource(source);
    setShowSourcesList(false);
  };

  const getLevel = (value: number, limit: number): string => {
    const percent = (value * 100) / limit;
    if (percent >= 100) {
      return 'high';
    } else if (percent >= 90 || percent > 100) {
      return 'normal';
    } else {
      return 'low';
    }
  };

  useEffect(() => {
    if (selectedSource)
      getEmissionsDashboard([selectedSource.id]).then((res) => setSourcesInfoList(res.list));
    else setSourcesInfoList([]);
  }, [selectedSource]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowSourcesList(false);
      }
    };

    if (showSourcesList) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSourcesList]);

  return (
    <Card
      styles={{
        width: '25%',
        position: 'relative',
      }}
    >
      <div className={classes['card__header']}>
        <img src={ERG} alt="icon" />
        <p style={{ whiteSpace: 'nowrap' }}>{selectedCompany?.name}</p>
        <div
          onClick={() => {
            setShowSourcesList((prev) => !prev);
          }}
        >
          <p>{selectedSource?.name}</p>
          {/* {showSourcesList && (
            <div className={classes['card__sources']}>
              {sourcesList
                ?.filter((it) => it.id !== selectedSource?.id)
                .map((item) => (
                  <span key={item.id} onClick={() => handleChooseSource(item)}>
                    {item.name}
                  </span>
                ))}
            </div>
          )} */}
          {selectedSource?.name && (
            <img
              src={ArrowBlack}
              alt="arr"
              className={classes[showSourcesList ? 'arrow2' : 'arrow']}
            />
          )}
        </div>
        <ToggleSwitch
          tooltipText={showDivisions ? 'Дивизион' : 'Предприятие'}
          checked={showDivisions}
          onToggleChange={changeCardInfoType}
        />
      </div>
      {showSourcesList && (
        <div className={classes['source-list-card']} ref={dropdownRef}>
          {sourcesList
            ?.filter((it) => it.id !== selectedSource?.id)
            .map((item) => (
              <div
                className={classNames(
                  classes['source-card-item'],
                  classes[`${item.exceeded ? 'source-card-item__active' : ''}`],
                )}
                key={item.id}
                onClick={() => handleChooseSource(item)}
              >
                <p>{item.name}</p>
                <p className={classNames(classes[`${item.exceeded ? 'triangle-up' : ''}`])}></p>
              </div>
            ))}
        </div>
      )}
      <div className={classes['line']}></div>
      <div className={classes['card__body']}>
        {sourcesInfoList[0]?.params &&
          Object.entries(sourcesInfoList[0]?.params).map(([key, value]) => (
            <div key={key}>
              <p>{key}</p>
              <div className={classes['card__flex']}>
                <div>
                  <p
                    className={`${classes['card__text']} ${
                      value.WasteConcentration.value > value.WasteConcentration.limit
                        ? classes['card__body__info--high']
                        : ''
                    }`}
                  >
                    Выбросы
                  </p>
                  <span className={classes['card__body__info2']}>{value.Emissions?.value} г/c</span>
                </div>
                <div>
                  {
                    // (value.WasteConcentration.value * 100) / value.WasteConcentration.limit >
                    //   90 && (
                    <>
                      <p className={classes['card__text']}>Концентрация</p>
                      <div className={classes['card__body__wrap']}>
                        <span
                          className={classNames(
                            classes['card__body__info2'],
                            classes[
                              `card__body__info--${getLevel(
                                value.WasteConcentration?.value,
                                value.WasteConcentration?.limit,
                              )}`
                            ],
                          )}
                        >
                          {value.WasteConcentration?.value?.toFixed(4)} мг/нм³
                        </span>
                      </div>
                    </>
                    // )
                  }
                </div>
              </div>
            </div>
          ))}
        <p className={classes['card__body__more']} onClick={scrollToBottom}>
          Подробнее
        </p>
      </div>
    </Card>
  );
};

export default CardInfo;
