import { Routes, Route, Navigate } from 'react-router-dom';
import { LoginPage, ForgotPassword, ResetPassword, MainPage } from './pages';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './components/layout';
import HomePage from './pages/home';
import Dashboard from './pages/dashboard';
import EmissionChart from './pages/emission-charts';
import Messages from './pages/messages';
import Settings from './pages/settings';
import Discharges from './pages/discharges';
import CheckupPage from './pages/events/checkup.page';
import ActivitiesPage from './pages/events/activities.page';
import AsmPage from './pages/main/asm';

export default function RoutesIndex() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword/:token" element={<ResetPassword />} />
        {/* <Route path="/" element={<HomePage />} /> */}
        <Route element={<Layout />}>
          <Route path="/" element={<MainPage />} />
          <Route path="/asm">
            <Route index element={<Navigate to="/asm/emissions" replace />} />
            <Route path="emissions" element={<AsmPage />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="dashboard/:type" element={<EmissionChart />} />
            <Route path="discharges" element={<Discharges />} />
          </Route>
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          {/* <Route path="/dashboard/:type" element={<EmissionChart />} /> */}
          <Route path="/messages" element={<Messages />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/events">
            <Route index element={<Navigate to="/events/checkup" replace />} />
            <Route path="checkup" element={<CheckupPage />} />
            <Route path="activities" element={<ActivitiesPage />} />
            {/* <Route path="activities" element={<Activities />} />
            <Route path="projects" element={<Projects />} />
            <Route path="permission" element={<Permission />} /> */}
          </Route>
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}
