export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const LANGUAGE = 'LANGUAGE';
export const ROLES = 'ROLES';

export enum Roles {
  USER = 'USER',
  ORGANIZATION = 'ORGANIZATION',
}

export const MONTHS_ID = [
  {
    label: 'Январь',
    name: 'Январь',
    id: 1,
  },
  {
    label: 'Февраль',
    name: 'Февраль',
    id: 2,
  },
  {
    label: 'Март',
    name: 'Март',
    id: 3,
  },
  {
    label: 'Апрель',
    name: 'Апрель',
    id: 4,
  },
  {
    label: 'Май',
    name: 'Май',
    id: 5,
  },
  {
    label: 'Июнь',
    name: 'Июнь',
    id: 6,
  },
  {
    label: 'Июль',
    name: 'Июль',
    id: 7,
  },
  {
    label: 'Август',
    name: 'Август',
    id: 8,
  },
  {
    label: 'Сентябрь',
    name: 'Сентябрь',
    id: 9,
  },
  {
    label: 'Октябрь',
    name: 'Октябрь',
    id: 10,
  },
  {
    label: 'Ноябрь',
    name: 'Ноябрь',
    id: 11,
  },
  {
    label: 'Декабрь',
    name: 'Декабрь',
    id: 12,
  },
];

export const MONTHS = [
  {
    label: 'Январь',
    value: 1,
  },
  {
    label: 'Февраль',
    value: 2,
  },
  {
    label: 'Март',
    value: 3,
  },
  {
    label: 'Апрель',
    value: 4,
  },
  {
    label: 'Май',
    value: 5,
  },
  {
    label: 'Июнь',
    value: 6,
  },
  {
    label: 'Июль',
    value: 7,
  },
  {
    label: 'Август',
    value: 8,
  },
  {
    label: 'Сентябрь',
    value: 9,
  },
  {
    label: 'Октябрь',
    value: 10,
  },
  {
    label: 'Ноябрь',
    value: 11,
  },
  {
    label: 'Декабрь',
    value: 12,
  },
];

export const YEARS = [
  // {
  //   label: '2021',
  //   value: 2021,
  // },
  // {
  //   label: '2022',
  //   value: 2022,
  // },
  {
    label: '2023',
    value: 2023,
  },
  {
    label: '2024',
    value: 2024,
  },
  {
    label: '2025',
    value: 2025,
  },
];

export const EventType = {
  ACTIVITY: 'rgba(54, 56, 60, 1)',
  CHECKUP: 'rgba(105, 93, 251, 1)',
  DEADLINE: 'rgba(183, 78, 191, 1)',
  PROJECT: 'rgba(254, 185, 0, 1)',
};

export const EventName = {
  ACTIVITY: 'Мероприятия',
  CHECKUP: 'Проверки',
  DEADLINE: 'Разрешения',
  PROJECT: 'Проекты',
};

export const EventActivityType = {
  PPM: 'Проект ППМ',
  LIQUIDATION: 'Ликвидация',
};

export const EventCheckupType = {
  PLANNED: 'Плановая (ГО)',
  BY_PURPOSE: 'По назначению (ГО)',
  INTERNAL_SEM: 'Внутренняя (СЭМ)',
  INTERNAL_UK: 'Внутренняя (УК)',
};

export const EventProjectType = {
  BUILDING: 'Строительный проект',
  EXQUISITE: 'Изыскательные работы',
  SCIENTIFIC: 'Научные исследования',
};
