import styles from './styles.module.scss';
import FullCalendarCard from './FullCalendarCard';
import { EventsRequest } from 'src/consts/types';
import { ColorInfoDTO } from '..';

export default function FullCalendarModal({
  eventsData,
  date,
  isEvents = false,
  backgroundOptions,
  customOnClick,
  selectedDate,
}: {
  eventsData: EventsRequest;
  date: Date;
  isEvents?: boolean;
  backgroundOptions: {
    key: string;
    options: ColorInfoDTO[];
  };
  customOnClick?: (event: any) => void;
  selectedDate?: Date;
}) {
  const month = Array(12).fill(0);

  return (
    <div className={`${styles['full-calendar']} ${isEvents ? styles['is-events'] : ''}`}>
      {month.map((month, index) => {
        return (
          <FullCalendarCard
            key={month + index}
            date={new Date(date.getFullYear(), index)}
            eventsData={eventsData}
            backgroundOptions={backgroundOptions}
            customOnClick={customOnClick}
            selectedDate={selectedDate}
          />
        );
      })}
    </div>
  );
}
