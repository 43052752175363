import axios, { AxiosRequestConfig } from 'axios';
import { getAccessToken, getRefreshToken, removeTokens, setTokens } from '../utils/token';
import { getToken } from './auth';
import { shiftDates } from '../utils/helpers';

const api = axios.create({
  baseURL: '/api',
} as AxiosRequestConfig);

let refreshTokenPromise: Promise<any> | null;

api.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (config.params) {
      shiftDates(config.params);
    }
    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = getRefreshToken();
    if ([401].includes(error.response?.status)) {
      if (refreshToken) {
        if (!refreshTokenPromise) {
          refreshTokenPromise = getToken(refreshToken).then((data) => {
            refreshTokenPromise = null;
            return data;
          });
        }

        return refreshTokenPromise
          .then((res) => {
            const { accessToken, refreshToken } = res;
            setTokens(accessToken, refreshToken);
            api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            return api(originalRequest);
          })
          .catch(() => {
            removeTokens();
            window.location.href = '/login';
            return Promise.reject(error);
          });
      } else {
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  },
);

export { api };
