import { ArrowDownBlue } from 'src/assets/icons';
import styles from './styles.module.scss';
import { useState } from 'react';

function YearSelector({
  option,
  selected,
  onChange,
}: {
  option: number[];
  selected: number;
  onChange: (val: number) => void;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={styles['year-selector']}>
      <div className={`${styles['selector-info']} ${isOpen ? styles['active'] : ''}`}>
        <p>{selected}</p>
        <img
          src={ArrowDownBlue}
          alt="arrow"
          className={styles['selector-btn']}
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      {isOpen && (
        <ul className={styles['year-list']}>
          {option.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  onChange(item);
                  setIsOpen(false);
                }}
                className={styles['year-item']}
              >
                {item}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default YearSelector;
