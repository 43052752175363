import React, { useCallback, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { useUserContext } from 'src/context/useUserContext';
import Diagrams from './Diagrams/Diagrams';
import Table from './Table/Table';
import {
  getCompanyInfo,
  getCompanyList,
  getDivisionsList,
  getExceedanceList,
  getHomePageInfo,
} from 'src/requests/source';
import {
  ICompany,
  IDateRange,
  IDivision,
  IExceedanceList,
  IHomeInfo,
  ISource,
} from 'src/consts/types';
import { format } from 'date-fns';
import Card from 'src/components/card';
import Table2 from './Table/Table2';
import Table3 from './Table/Table3';
import { findColorById, getShortName } from 'src/utils/helpers';
import { ArrowBlue } from 'src/assets/icons';
import Modal from 'src/components/modal/Modal';
import Dropdown from 'src/components/dropdown';
import { MONTHS, YEARS } from 'src/consts/enum';
import { Loader2 } from 'lucide-react';
import MapPage from './Map/AsmMapPage';

const PAGE_SIZE = 40;

const AsmPage: React.FC = () => {
  const today = new Date();
  const { checkRole } = useUserContext();
  const [showHighCompanyModal, setShowHighCompanyModal] = useState<boolean>(false);
  const [homeTableData, setHomeTableData] = useState<IHomeInfo[]>([]);

  const [companyList, setCompanyList] = useState<ICompany[]>([]);
  const [companyListWithLevel, setCompanyListWithLevel] = useState<ICompany[]>([]);
  const [divisionListWithHighLevel, setDivisionListWithHighLevel] = useState<IDivision[]>([]);
  const [divisionListWithLevel, setDivisionListWithLevel] = useState<IDivision[]>([]);

  const [companyDivisionList, setCompanyDivisionList] = useState<IDivision[]>([]);
  const [companyListWithHighLevel, setCompanyListWithHighLevel] = useState<ICompany[]>([]);
  const [companyListWithLevelCopy, setCompanyListWithLevelCopy] = useState<ICompany[]>([]);

  const [selectedCompanies, setSelectedCompanies] = useState<(ICompany | IDivision)[]>([]);
  const [selectedSources, setSelectedSources] = useState<ISource[]>([]);

  const [firstSelectedCompany, setFirstSelectedCompany] = useState<ICompany>();
  const [firstSelectedSource, setFirstSelectedSource] = useState<ISource>();
  const [showDivisions, setShowDivisions] = useState<boolean>(false);
  const [loadingHomeTable, setLoadingHomeTable] = useState<boolean>(false);

  const [selectedRange, setSelectedRange] = useState<IDateRange>({
    dateFrom: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    dateTo: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
  });

  const [exceedanceListLoading, setExceedanceListLoading] = useState<boolean>(false);
  const [exceedanceList, setExceedanceList] = useState<IExceedanceList[]>([]);
  const [exceedanceListPage, setExceedanceListPage] = useState<number>(0);
  const [exceedanceListPageSize] = useState<number>(PAGE_SIZE);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [exceedanceDate, setExceedanceDate] = useState<{ year: string; month: string }>({
    year: today.getFullYear().toString(),
    month: (today.getMonth() + 1).toString(),
  });

  const fetchDivisions = () => {
    getDivisionsList().then((data) => {
      const list = data.list.map((item: IDivision) => ({
        ...item,
        backgroundColor: findColorById(item.id),
        label: item.name,
      }));
      setCompanyDivisionList(list);
    });
  };

  useEffect(() => {
    fetchDivisions();
  }, []);

  const fetchCompanyInfo = async () => {
    const companyPromises = companyList.map(async (item) => {
      const res = await getCompanyInfo(item.id);
      return {
        ...item,
        backgroundColor: findColorById(item.id),
        level: res.data.level,
        label: item.name,
        name: getShortName(item.name),
      };
    });
    const updatedCompanies = await Promise.all(companyPromises);
    setCompanyListWithHighLevel(updatedCompanies.filter((item) => item.level === 'HIGH'));
    setCompanyListWithLevel(updatedCompanies);
    setCompanyListWithLevelCopy(updatedCompanies);
  };

  const fetchDivisionInfo = async () => {
    const divisionPromises = companyDivisionList.map(async (item) => {
      const res = await getCompanyInfo(item.id);
      return {
        ...item,
        backgroundColor: findColorById(item.id),
        level: res.data.level,
        label: item.name,
        name: getShortName(item.name),
      };
    });

    const updatedDivisions = await Promise.all(divisionPromises);
    setDivisionListWithHighLevel(updatedDivisions.filter((item) => item.level === 'HIGH'));
    setDivisionListWithLevel(updatedDivisions);
  };

  const getAmountOfHighLevelCompanies = () => {
    const date =
      exceedanceDate.year +
      '-' +
      (exceedanceDate.month.split('').length === 1
        ? '0' + exceedanceDate.month
        : exceedanceDate.month);

    setExceedanceListLoading(true);
    getExceedanceList(date, exceedanceListPage, exceedanceListPageSize)
      .then((res) => {
        setHasMore(res.list.length > 0);
        setExceedanceList((prev) => [...prev, ...res.list]);
      })
      .finally(() => {
        setExceedanceListLoading(false);
      });
  };

  useEffect(() => {
    setExceedanceListPage(0);
    setHasMore(false);
    setExceedanceList([]);
    setExceedanceListLoading(false);
  }, [exceedanceDate]);

  useEffect(() => {
    if (companyList.length > 0) {
      void fetchCompanyInfo();
    }
  }, [companyList]);

  useEffect(() => {
    if (!checkRole('ECOLOGIST') && companyDivisionList.length > 0) {
      void fetchDivisionInfo();
    }
  }, [companyDivisionList]);

  useEffect(() => {
    firstSelectedCompany && setSelectedCompanies([firstSelectedCompany]);
    firstSelectedSource && setSelectedSources([firstSelectedSource]);
  }, [firstSelectedCompany, firstSelectedSource]);

  useEffect(() => {
    setLoadingHomeTable(true);
    getHomePageInfo()
      .then((res) => {
        setHomeTableData(
          res.list.map((item) => ({ ...item, orgColor: findColorById(item.companyId) })),
        );
      })
      .finally(() => {
        setLoadingHomeTable(false);
      });
    getCompanyList().then((res) => {
      setCompanyList(res.list.map((item) => ({ ...item, name: item.name })));
    });
  }, []);

  useEffect(() => {
    showHighCompanyModal && getAmountOfHighLevelCompanies();
  }, [exceedanceDate, showHighCompanyModal, exceedanceListPage]);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
      if (
        (Math.ceil(clientHeight + scrollTop) === scrollHeight ||
          Math.ceil(clientHeight + scrollTop + 1) === scrollHeight) &&
        hasMore &&
        !exceedanceListLoading
      ) {
        setExceedanceListPage((prevPage) => prevPage + 1);
      }
    },
    [exceedanceListLoading, hasMore],
  );

  return (
    <>
      <div className={classes['content']}>
        <div className={classes['content__grid']}>
          <Card className={classes['content__table']}>
            <table className={`${classes['table']}`}>
              <thead>
                <tr>
                  <th>Источник</th>
                  <th>Загрязняющее вещество</th>
                  <th>Норматив ПДВ, г/с</th>
                  <th>Превышение норматива ПДВ, г/с</th>
                  <th>Норматив ПДК, мг/нм3</th>
                  <th>Превышение норматива ПДК, мг/нм3</th>
                </tr>
              </thead>
              <tbody>
                {homeTableData.map((item: IHomeInfo, index: number) => (
                  <tr key={index}>
                    <td className={classes['flex']}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <circle cx="5" cy="5" r="5" fill={item.orgColor} />
                      </svg>
                      {item.sourceName}
                    </td>
                    <td>{item.type}</td>
                    <td>{item.params?.Emissions?.limit}</td>
                    <td style={{ color: '#FF3D3D' }}>{item.params?.Emissions?.value}</td>
                    <td>{item.params?.WasteConcentration?.limit}</td>
                    <td style={{ color: '#FF3D3D' }}>{item.params?.WasteConcentration?.value}</td>
                  </tr>
                ))}
                {!loadingHomeTable ? (
                  !homeTableData.length && (
                    <tr>
                      <td colSpan={6}>Нет данных</td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <div className="loader">
                        <Loader2 color="#6496fb" />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Card>
          {!checkRole('ECOLOGIST') && (
            <Card className={classes['content__info']}>
              <div className={classes['info-wrapper']}>
                <div className={classes['info']}>
                  <p>{showDivisions ? 'Общее кол-во дивизионов' : 'Общее кол-во предприятий'}</p>
                  <span>
                    {showDivisions ? companyDivisionList.length : companyListWithLevel.length}
                  </span>
                </div>
                <div className={classes['info-divider']}></div>
                <div className={classes['info']}>
                  <p>
                    {showDivisions
                      ? 'Кол-во дивизионов с превышениями'
                      : 'Кол-во предприятий с превышениями'}
                  </p>
                  <span
                    className={classes['info__amount']}
                    onClick={() => setShowHighCompanyModal(!showHighCompanyModal)}
                  >
                    {showDivisions
                      ? divisionListWithHighLevel.length
                      : companyListWithHighLevel.length}
                    <img alt="" src={ArrowBlue} />
                  </span>
                </div>
              </div>
            </Card>
          )}
        </div>
        <MapPage
          divisionsListWithLevel={divisionListWithLevel}
          showDivisions={showDivisions}
          setShowDivisions={setShowDivisions}
          selectedSource={firstSelectedSource}
          setSelectedSource={setFirstSelectedSource}
          companyListWithLevel={companyListWithLevel}
          companyList={companyListWithLevelCopy}
          selectedCompany={firstSelectedCompany}
          setSelectedCompany={setFirstSelectedCompany}
          setCompanyListWithLevel={setCompanyListWithLevel}
          divisionsList={companyDivisionList}
          setDivisionListWithLevel={setDivisionListWithLevel}
        />
        {firstSelectedSource && (
          <>
            <Diagrams
              showDivisions={showDivisions}
              companyDivisionList={companyDivisionList}
              companyList={companyListWithLevel}
              firstSelectedCompany={firstSelectedCompany}
              firstSelectedSource={firstSelectedSource}
            />
            <Table
              showDivisions={showDivisions}
              companyDivisionList={companyDivisionList}
              companyList={companyListWithLevel}
              dates={selectedRange}
              setDates={setSelectedRange}
              selectedCompanies={selectedCompanies}
              selectedSources={selectedSources}
              setSelectedCompanies={setSelectedCompanies}
              setSelectedSources={setSelectedSources}
            />
            <div className={classes['tables']}>
              <Table2 selectedSources={selectedSources} />
              <Table3 selectedSources={selectedSources} />
            </div>
          </>
        )}
      </div>
      <Modal
        style={{
          maxHeight: '800px',
        }}
        onScroll={handleScroll}
        setShow={setShowHighCompanyModal}
        show={showHighCompanyModal}
        header={
          <div className={classes['high-company-modal-wrapper']}>
            <div className={classes['high-company-modal-wrapper__left']}>
              {showDivisions ? 'Дивизионы' : 'Предприятия'} с превышениями
            </div>
            <div className={classes['high-company-modal-wrapper__right']}>
              <Dropdown
                placeholder={MONTHS[today.getMonth()].label}
                options={MONTHS}
                onSelect={(month) => {
                  setExceedanceDate({ ...exceedanceDate, month: month.toString() });
                }}
              />
              <Dropdown
                placeholder={exceedanceDate.year}
                options={YEARS}
                onSelect={(year) => {
                  setExceedanceDate({ ...exceedanceDate, year: year.toString() });
                }}
              />
            </div>
          </div>
        }
        handleClose={() => {
          setShowHighCompanyModal(false);
          setExceedanceList([]);
        }}
      >
        <table className={classes['table']}>
          <thead
            style={{
              top: '-30px',
            }}
          >
            <tr>
              <th>{showDivisions ? 'Дивизион' : 'Предприятие'}</th>
              <th>Источник</th>
              <th>Загрязняющее вещество</th>
              <th>
                Норматив ПДВ, <br />
                г/с
              </th>
              <th>
                Превышение норматива ПДВ, <br />
                г/с
              </th>
              <th>
                Норматив ПДК, <br /> мг/нм3
              </th>
              <th>
                Превышение норматива ПДК, <br /> мг/нм3
              </th>
              <th>Дата и время Превышений</th>
            </tr>
          </thead>
          <tbody>
            {exceedanceList.length > 0 &&
              exceedanceList.map((item: IExceedanceList, index: number) => (
                <tr key={index}>
                  <td className={classes['flex']}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                    >
                      <circle
                        cx="5"
                        cy="5"
                        r="5"
                        fill={findColorById(item[showDivisions ? 'divisionId' : 'id'])}
                      />
                    </svg>
                    {showDivisions ? item.divisionName : getShortName(item.companyName)}
                  </td>
                  <td>{item.sourceName}</td>
                  <td>{item.type}</td>
                  <td>{item?.emissionLimit?.toFixed(4)}</td>
                  <td style={{ color: '#FF3D3D' }}>{item.emission}</td>
                  <td>{item?.wasteConcentrationLimit?.toFixed(4)}</td>
                  <td style={{ color: '#FF3D3D' }}>{item.wasteConcentration}</td>
                  <td>{format(new Date(item.logDate), 'dd.MM.yyyy HH:mm')}</td>
                </tr>
              ))}

            {exceedanceListLoading && (
              <tr>
                <td colSpan={9}>
                  <>Загрузка данных...</>
                </td>
              </tr>
            )}

            {exceedanceList.length == 0 && !exceedanceListLoading && (
              <tr>
                <td colSpan={9}>
                  Нет данных по&nbsp;
                  {MONTHS[Number(exceedanceDate.month) - 1].label + ' ' + exceedanceDate.year}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Modal>
    </>
  );
};

export default AsmPage;
