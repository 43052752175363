import YearSelector from 'src/components/yearSelector';
import styles from './styles.module.scss';
import { EventName, EventType } from 'src/consts/enum';

export default function FullCalendarHeader({
  date,
  setDate,
}: {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
}) {
  const currentDate = new Date();
  const years = Array.from({ length: 26 }, (_, i) => currentDate.getFullYear() - 5 + i);

  return (
    <div className={styles['full-calendar-header']}>
      <YearSelector
        option={years}
        selected={date.getFullYear()}
        onChange={(val: number) => setDate(new Date(val, 0, 1))}
      />
      <div className={styles['color-list']}>
        {Object.entries(EventType).map(([key, value]) => {
          return (
            <div key={key} className={styles['list-item']}>
              <span style={{ background: value }}></span>
              {EventName[key as keyof typeof EventName]}
            </div>
          );
        })}
      </div>
    </div>
  );
}
