import { EventName, EventType } from 'src/consts/enum';
import styles from './styles.module.scss';

export default function ColorInfo() {
  return (
    <div className={styles['color-info-wrap']}>
      {Object.entries(EventType).map(([key, value]) => {
        return (
          <div key={key} className={styles['info-item']}>
            <span style={{ background: `${value}` }}></span>
            {EventName[key as keyof typeof EventName]}
          </div>
        );
      })}
    </div>
  );
}
