import { AmountTypes, IFilterParams, IModalField } from './types';

// ACTIVITY | МЕРОПРИЯТИЯ

export const EventActivityTypeOptions = [
  {
    label: 'Проект ППМ',
    value: 'PPM',
    color: '#695DFB',
  },
  {
    label: 'Ликвидация',
    value: 'LIQUIDATION',
    color: '#FEB900',
  },
];

export const activityFields = [
  {
    key: 'companyName',
    title: 'Наименование предприятия',
  },
  {
    key: 'objectName',
    title: 'Наименование объекта',
  },
  {
    key: 'eventName',
    title: 'Наименование мероприятия',
  },
  {
    key: 'eventAmount',
    title: 'Общая стоимость',
  },
  {
    key: 'deadline',
    title: 'Срок выполнения',
  },
];

export const activityCreateFields: IModalField[] = [
  {
    key: 'companyName',
    title: 'Наименование предприятия',
    type: 'select',
    dictKey: 'companyList',
  },
  {
    key: 'objectName',
    title: 'Наименование объекта',
    type: 'select',
    dictKey: 'objectList',
  },
  {
    key: 'eventName',
    title: 'Наименование мероприятия',
  },
  {
    key: 'eventType',
    title: 'Вид мероприятия',
    type: 'select',
    dictKey: 'eventTypeList',
  },
  {
    key: 'totalAmount',
    title: 'Общая стоимость',
  },
  {
    key: 'deadline',
    title: 'Срок выполнения',
    type: 'date-range',
  },
  {
    key: 'date',
    title: 'Дата',
    type: 'date',
    subTitle: '(отображение в календаре)',
  },
  {
    title: 'Объем планируемых работ',
    key: 'workVolume',
    subTitle: 'необязательное окно',
  },
  {
    title: 'Ожидаемый экологический эффект от мероприятия, тонн/год ',
    key: 'expectedEffect',
    subTitle: 'необязательное окно',
  },
  {
    title: 'Статус выполнения',
    key: 'status',
    type: 'select',
    dictKey: 'statusList',
  },
];

export const activityNotificationFields: IModalField[] = [
  {
    key: 'companyName',
    title: 'Наименование предприятия',
    type: 'select',
    dictKey: 'companyList',
  },
  {
    key: 'objectName',
    title: 'Наименование объекта',
    type: 'select',
    dictKey: 'objectList',
  },
  {
    key: 'eventName',
    title: 'Наименование мероприятия',
    type: 'select',
    dictKey: 'eventsList',
  },
  {
    key: 'date',
    title: 'Дата',
    type: 'date',
    subTitle: '(отображение в календаре)',
  },
  {
    key: 'notification',
    title: 'Напоминание/Примечание',
  },
];

export const activityBackgroundOptions = {
  key: 'activityType',
  options: EventActivityTypeOptions.map((item) => ({
    ...item,
    title: item.value,
  })),
};

export const getActivityType = (type: string) => {
  return (
    EventActivityTypeOptions.find((item) => item.value === type) || EventActivityTypeOptions[0]
  );
};

export const activityModalTabs: {
  value: 'new' | 'notification';
  label: string;
}[] = [
  {
    value: 'notification',
    label: 'Напоминание/примечание',
  },
  {
    value: 'new',
    label: 'Новое мероприятие',
  },
];

export const activityTableFilters: {
  title: string;
  key: keyof IFilterParams;
  dictKey: string;
}[] = [
  {
    title: 'Предприятие / Дивизион',
    key: 'companyId',
    dictKey: 'companyList',
  },
  {
    key: 'objectId',
    title: 'Объект',
    dictKey: 'objectList',
  },
  {
    key: 'eventType',
    title: 'Вид мероприятия',
    dictKey: 'eventTypeList',
  },
  {
    key: 'amountType',
    title: 'Общая стоимость',
    dictKey: 'eventAmountList',
  },
];

export const activityHeaders = [
  { title: 'Наименование предприятия', key: 'companyName' },
  { title: 'Наименование объекта', key: 'objectName' },
  { title: 'Наименование мероприятия', key: 'eventName' },
  { title: 'Объем планируемых работ', key: 'workAmount' },
  {
    title: 'Период выполнения мероприятия, (согласно утвержденному плану)',
    key: 'period',
    children: [
      { title: 'Начало', key: 'start' },
      { title: 'Конец', key: 'end' },
    ],
  },
  { title: 'Общая стоимость, тыс.тенге', key: 'eventAmount' },
  { title: '% освоенных средств', key: 'used' },
  { title: '% выполненных работ', key: 'done' },
  { title: 'Ожидаемый экологический эффект от мероприятия, тонн/год', key: 'effect' },
  { title: 'Статус выполнения', key: 'status' },
  { title: 'Примечание', key: 'additional' },
];

export const AmountTypeOptions: { label: string; value: AmountTypes }[] = [
  {
    value: AmountTypes.NONE,
    label: 'Без затрат',
  },
  {
    value: AmountTypes.ABOVE_THRESHOLD,
    label: 'Выше порога',
  },
  {
    value: AmountTypes.UNDER_THRESHOLD,
    label: 'Ниже порога',
  },
];

// CHECKUP | ПРОВЕРКИ

export const EventCheckupTypeOptions = [
  {
    label: 'Плановая (ГО)',
    value: 'PLANNED',
    color: '#695DFB',
  },
  {
    label: 'По назначению (ГО)',
    value: 'BY_PURPOSE',
    color: '#36383C',
  },
  {
    label: 'Внутренняя (СЭМ)',
    value: 'INTERNAL_SEM',
    color: '#FEB900',
  },
  {
    label: 'Внутренняя (УК)',
    value: 'INTERNAL_UK',
    color: '#B74EBF',
  },
];

export const checkupBackgroundOptions = {
  key: 'checkupType',
  options: EventCheckupTypeOptions.map((item) => ({
    ...item,
    title: item.value,
  })),
};

export const getEventType = (type: string, eventType: string) => {
  if (eventType === 'CHECKUP') {
    return (
      EventCheckupTypeOptions.find((item) => item.value === type) || EventCheckupTypeOptions[0]
    );
  } else if (eventType === 'ACTIVITY') {
    return (
      EventActivityTypeOptions.find((item) => item.value === type) || EventActivityTypeOptions[0]
    );
  }
};
