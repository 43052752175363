import { EventsRequest } from 'src/consts/types';
import { api } from './api';

const baseUrl = '/manager';

export const getCompanyList = () => {
  return api.get(`${baseUrl}/company/list`).then((res) => res.data);
};

export const getCompanyNormsList = () => {
  return api.get(`${baseUrl}/company/norma/list`).then((res) => res.data);
};

export const getEvents = (date: { from: Date; to: Date }): Promise<{ data: EventsRequest; msg: string; success: boolean }> => {
  return api.get(`${baseUrl}/calendar`, { params:{
    ...date
  } }).then((res) => res.data);
};