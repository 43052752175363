import React, { FC, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { Icon } from 'src/components';

interface IProps {
  items: ICard[];
}

export interface ICard {
  id: number;
  color: string;
  companyName: string;
  norma: number;
  value: number;
}

const CardSwiper: FC<IProps> = ({ items }) => {
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const scrollLeft = () => {
    containerRef.current?.scrollBy({ left: -330, behavior: 'smooth' });
  };

  const scrollRight = () => {
    containerRef.current?.scrollBy({ left: 330, behavior: 'smooth' });
  };

  return (
    <>
      {/* Background Blur Overlay when Expanded */}
      {expanded && <div className={classes.overlay} onClick={toggleExpand} />}

      <div className={`${classes.cardSliderContainer} ${expanded ? classes.expanded : ''}`}>
        <div className={classes.controls}>
          <div>
            <button disabled={expanded} onClick={scrollLeft} className={classes.navButton}>
              <Icon name="ChevronLeft" size={24} />
            </button>
            <button disabled={expanded} onClick={scrollRight} className={classes.navButton}>
              <Icon name="ChevronRight" size={24} />
            </button>
          </div>

          <button onClick={toggleExpand} className={classes.toggleButton}>
            <Icon name={expanded ? 'ChevronsDown' : 'ChevronsUp'} size={24} />
          </button>
        </div>

        <div className={classes.cardSlider} ref={containerRef}>
          {items.map((card, index) => (
            <div key={index} className={classes.card}>
              <div className={classes.card__title}>
                <span
                  className={classes.card__circle}
                  style={{ background: card.color || '#000' }}
                />
                <span>{card.companyName}</span>
              </div>
              <div className={classes.card__values}>
                <div className={classes.card__value}>
                  <span className={classes.card__value__number}>
                    {(card.norma ?? 0)?.toFixed(2)} <span className={classes.card__value__small}>т</span>
                  </span>
                  <span className={classes.card__value__title}>Норматив на текущий год</span>
                </div>
                <div className={classes.card__value}>
                  <span className={classes.card__value__number}>
                    {(card.value ?? 0)?.toFixed(2)} <span className={classes.card__value__small}>т</span>
                  </span>
                  <span className={classes.card__value__title}>Израсходованный норматив</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CardSwiper;
