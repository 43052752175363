import { Check, X } from 'lucide-react';
import { FC, useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import classes from './styles.module.scss';

interface IProps {
  value: string | number;
  onSave: (value: string | number) => void;
  type?: 'text' | 'number' | 'textarea';
}

export const EditableCell: FC<IProps> = ({ value, onSave, type = 'text' }) => {
  const [editValue, setEditValue] = useState(value?.toString());
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null);

  useEffect(() => {
    if (type === 'textarea' && isEditing) adjustTextareaHeight();
  }, [editValue, isEditing]);

  // Adjusts textarea height dynamically when editing
  const adjustTextareaHeight = () => {
    if (inputRef.current && type === 'textarea') {
      const textarea = inputRef.current as HTMLTextAreaElement;
      textarea.style.height = 'auto'; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Expand dynamically
    }
  };

  const handleSave = () => {
    onSave(editValue);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditValue(value?.toString());
    setIsEditing(false);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (inputRef.current && inputRef.current.contains(e.relatedTarget)) return;
    setIsEditing(false);
  };

  return (
    <div className={clsx(classes.inputWrapper)}>
      {type === 'textarea' ? (
        <textarea
          ref={inputRef as React.RefObject<HTMLTextAreaElement>}
          value={editValue}
          onChange={(e) => {
            setEditValue(e.target.value);
            if (isEditing) adjustTextareaHeight();
          }}
          onFocus={() => setIsEditing(true)}
          onBlur={handleBlur}
          className={clsx(classes.tableInput, classes.textarea, isEditing && classes.expanded)}
          rows={isEditing ? undefined : 1} // 1 row when not editing
          style={{ height: isEditing ? `${inputRef.current?.scrollHeight}px` : '1.5rem' }} // Expand when editing
        />
      ) : (
        <input
          ref={inputRef as React.RefObject<HTMLInputElement>}
          type={type}
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          onFocus={() => setIsEditing(true)}
          onBlur={handleBlur}
          className={clsx(classes.tableInput)}
        />
      )}
      {isEditing && (
        <div className={classes.actionButtons}>
          <Check
            className={clsx(classes.icon, classes.success)}
            onClick={handleSave}
            tabIndex={0} size={20}
          />
          <X className={clsx(classes.icon, classes.cancel)} onClick={handleCancel} tabIndex={0} size={20} />
        </div>
      )}
    </div>
  );
};
