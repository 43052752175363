import styles from './styles.module.scss';
import { CalendarDay, DayPicker } from 'react-day-picker';
import { ru } from 'react-day-picker/locale';
import CalendarCardItem from './CalendarCardItem';
import { EventsRequest } from 'src/consts/types';

export default function CalendarCard({
  eventsData,
  date,
}: {
  eventsData: EventsRequest;
  date: Date;
}) {
  const today = new Date();
  const uniqueGroupedEvents = Object.entries(eventsData).reduce((acc, [date, events]) => {
    acc[date] = events.filter(
      (event, index, self) => self.findIndex((e) => e.type === event.type) === index,
    );
    return acc;
  }, {} as EventsRequest);
  const CustomDaycell = ({
    day,
    modifiers,
  }: {
    day: CalendarDay;
    modifiers: Record<string, boolean>;
  }) => {
    return (
      <CalendarCardItem
        day={day}
        groupedEvents={eventsData}
        date={date}
        uniqueGroupedEvents={uniqueGroupedEvents}
        modifiers={modifiers}
      />
    );
  };

  return (
    <div>
      <DayPicker
        key={date.getTime()}
        month={date}
        components={{ Day: CustomDaycell }}
        modifiers={{ today }}
        classNames={{
          root: styles['custom-calendar'],
          weekday: styles['custom-weekday'],
          month_caption: styles['hide-element'],
          nav: styles['hide-element'],
          month_grid: styles['custom-table'],
          // today: styles['custom-today'],
        }}
        firstWeekContainsDate={1}
        locale={ru}
        formatters={{
          formatWeekdayName: (weekday) =>
            weekday.toLocaleDateString('ru-RU', { weekday: 'short' }).slice(0, 1),
        }}
      />
    </div>
  );
}
