import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { ICompany, ISource } from 'src/consts/types';
import { findTopByLatitude, findLeftByLongitude, findByRuCompanyName } from 'src/utils/helpers';
import { getCompanySourceList, getDivisionsById } from 'src/requests/source';
import { MapContainer, useMap } from 'react-leaflet';
import classes from './styles.module.scss';
import KazakhstanMap from './KazakhstanMapAsm';

export interface IMapPoint extends ICompany {
  top: number;
  left: number;
}

interface Props {
  points: ICompany[];
  setSourcesList: Dispatch<SetStateAction<ISource[]>>;
  setSelectedCompany: Dispatch<SetStateAction<ICompany | undefined>>;
  setSelectedSource: Dispatch<SetStateAction<ISource | undefined>>;
  isDivision?: boolean;
}

const FitBoundsOnResize: React.FC<{ bounds: L.LatLngBoundsLiteral }> = ({ bounds }) => {
  const map = useMap();

  useEffect(() => {
    map.fitBounds(bounds);

    const handleResize = () => {
      map.fitBounds(bounds);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [bounds, map]);

  return null;
};

const Map: React.FC<Props> = ({
  points,
  setSourcesList,
  setSelectedCompany,
  setSelectedSource,
  isDivision,
}) => {
  const [mapPoints, setMapPoints] = useState<IMapPoint[]>([]);
  const mapContainerRef = useRef<HTMLDivElement>(null);

  // Координаты границ Казахстана
  const bounds: L.LatLngBoundsLiteral = [
    [39.49, 46.492],
    [55.63, 87.3152],
  ];

  useEffect(() => {
    const found = points.map((item) => ({
      ...item,
      top: findTopByLatitude(item.latitude),
      left: findLeftByLongitude(item.longitude),
    }));
    setMapPoints(found);
  }, [points]);

  return (
    <div
      className={classes['map']}
      style={{ position: 'relative', width: '100%', height: '700px' }}
      ref={mapContainerRef}
    >
      <MapContainer
        style={{ width: '100%', height: '100%' }}
        center={[48, 68]}
        zoom={5}
        attributionControl={false}
        zoomControl={false}
        dragging={false}
        scrollWheelZoom={false}
        maxBounds={bounds}
        maxZoom={10}
        zoomSnap={0.00001}
      >
        <FitBoundsOnResize bounds={bounds} />
        <KazakhstanMap
          points={mapPoints}
          isDivision={isDivision}
          handleIconClick={(item, companyName) => {
            const companyEnName = findByRuCompanyName(companyName);
            setSelectedCompany(item);
            (isDivision ? getDivisionsById : getCompanySourceList)([item.id]).then((res) => {
              const filteredList = res.list.filter((item: any) =>
                item.name.includes(companyEnName)
              );
              setSourcesList(isDivision ? filteredList : res.list);
              setSelectedSource(isDivision ? filteredList[0] : res.list[0]);
            });
          }}
        />
      </MapContainer>
    </div>
  );
};

export default Map;
